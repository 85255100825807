import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
//import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import {FETCH_BILL_OPERATION} from 'constants/action-types';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    width: "95%",
    marginBottom: 20,
    margin: '0 auto'
  },
  dataPicker: {
    fontSize: '16px',
    maxWidth: '90px',
    borderStyle: 'none',
    borderBottom: '1px solid #000',
    marginLeft: '10px'
  },
  btnShow: {
    textTransform: 'none',
    fontSize: '12px',
    float: 'right'
  },
});

//первый день месяца
const firstDayOfYear = () => {
  const d = new Date();
  d.setDate(1);
  d.setMonth(0)
  return d;
}

class BillView extends PureComponent {
  state = {
    startDate: firstDayOfYear(),
    endDate: new Date(),
    page: 0,
    rowsPerPage: 10,
  }

  constructor(props) {
    super(props);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchBillOperationData(this.buildQueryParam(0,this.state.rowsPerPage));
  }

  formatDatetime(epoch) {
    return moment(new Date(epoch)).tz('Europe/Moscow').format('DD.MM.YYYY HH:mm');
  }

  handleBtnShow() {
    this.props.fetchBillOperationData(this.buildQueryParam(this.state.page,this.state.rowsPerPage));
  }

  buildQueryParam(newPage, newPageSize) {
    const prm = {
      startDate: this.state.startDate.getTime(),
      endDate: this.state.endDate.getTime(),
      page: newPage, //this.state.page - 1,
      size: newPageSize,
    }
    return Object.keys(prm).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(prm[k])}`).join('&');
  }

  startDateChange(date) {
    this.setState({startDate: date})
  }

  endDateChange(date) {
    this.setState({endDate: date})
  }

  handlePaginationChange = (event) => {
    const val = parseInt(event.target.value, 10);
    this.setState({rowsPerPage:val});
    this.setState( {page: 0});
    this.props.fetchBillOperationData(this.buildQueryParam(0, val));
  }

  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
    this.props.fetchBillOperationData(this.buildQueryParam(newPage, this.state.rowsPerPage));
  }


  _printState(item) {
    if (item.paymentState) {
      if (item.paymentState === 'FINISHED')
        return "";
      if (item.paymentState === 'FAILED')
        return "(Платеж не проведен)";
      return "";
    }
    if (item.invoiceState) {
      if (item.invoiceState === 'UNPAID')
        return "(Не прошло, недостаточно средств на счете)";
      if (item.invoiceState === 'PAID')
        return "";
      if (item.invoiceState === 'CANCELLED')
        return "(Отменено)";
      return "";
    }
  }

  _getFontColor(item) {

    if (item.paymentState) {
      if (item.paymentState === 'CREATED') return "yellow";
      if (item.paymentState === 'FAILED') return "red";
    }
    if (item.invoiceState) {
      if (item.invoiceState === 'UNPAID') return "red";
      if (item.invoiceState === 'CANCELLED') return "red";
    }
    return "";
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} disableTypography={true}
                      title={
                        <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
                          Движение средств по счету <hr/>
                        </Typography>}

          />
          <CardContent>
            <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center"
            >
              <Grid item xs={4}>
                <span>Начиная :</span>
                <DatePicker className={classes.dataPicker}
                            dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={this.stFartDateChange}
                />
              </Grid>
              <Grid item xs={4}>
                <span>Заканчивая :</span>
                <DatePicker className={classes.dataPicker}
                            dateFormat="dd/MM/yyyy" selected={this.state.endDate} onChange={this.endDateChange}
                />
              </Grid>

              <Grid item xs={4}>
                <Button size="small" variant="outlined" color="primary" className={classes.btnShow}
                        onClick={() => this.handleBtnShow()}>
                  Показать
                </Button>
              </Grid>
            </Grid>

            <Table className={classes.table}>
              <TableHead>
                <TableRow >
                  <TableCell align="left">Время операции</TableCell>
                  <TableCell align="left">Тип операции</TableCell>
                  <TableCell align="right">Сумма операции, руб.</TableCell>
                  <TableCell align="left">Описание</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.operationData.map(item => (
                  <TableRow key={item.moment}>
                    <TableCell align="left" style={{color: this._getFontColor(item)}} >{this.formatDatetime(item.moment)}</TableCell>
                    <TableCell align="left" style={{color: this._getFontColor(item)}} >{item.type} {this._printState(item)}</TableCell>
                    <TableCell align="right" style={{color: this._getFontColor(item)}} >{(item.sum / 100).toFixed(2)}</TableCell>
                    <TableCell align="left" style={{color: this._getFontColor(item)}} >{item.comment}</TableCell>
                    <TableCell align="center" style={{color: this._getFontColor(item)}} ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="Записей на страницу:"
                    labelDisplayedRows={(obj) => {
                      var from = obj.from, to = obj.to, count = obj.count;
                      return "".concat(from, "-").concat(to, " из ").concat(count);
                    }
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    colSpan={4}

                    count={this.props.totalSize}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.page}

                    SelectProps={{inputProps: {'aria-label': 'rows per page'}, native: true,}}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handlePaginationChange}
                  />
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableFooter>
            </Table>

          </CardContent>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const operation = state.billOperation && state.billOperation.payload && state.billOperation.payload.data

  return {
    operationData: operation ? operation.content : [],
    totalSize: (operation && operation.totalElements) ? operation.totalElements : 0,
    page: (operation && operation.number) ? (operation.number) : 0,
    rowsPerPage: (operation && operation.size) ? operation.size : 10
  };
}

const mapDispatchToProps = dispatch => ({
  fetchBillOperationData: (payload) => {
    dispatch({type: FETCH_BILL_OPERATION, payload})
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillView));
