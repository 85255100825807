import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import {FETCH_OPERATION} from 'constants/action-types';
//import "react-datepicker/dist/react-datepicker.css";
//import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import moment from "moment-timezone";


const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    minWidth: 720,
    marginBottom: 20,
    margin: '0 auto',
  },
  dataPicker: {
    fontSize: '16px',
    maxWidth: '90px',
    borderStyle: 'none',
    borderBottom: '1px solid #000',
    marginLeft: '10px'
  },
  btnShow: {
    textTransform: 'none',
    fontSize: '12px',
    float: 'right'
  },

});

//первый день месяца
const firstDayOfMonth = () => {
  const d = new Date();
  d.setDate(1);
  return d;
}

class Statistic extends PureComponent {
  state = {
    startDate: firstDayOfMonth(),
    endDate: new Date(),
    page: 0,
    rowsPerPage: 10
  };

  constructor(props) {
    super(props);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchOperationData(this.buildQueryParam(0,this.props.rowsPerPage));
  }

  handleBtnShow() {
    this.props.fetchOperationData(this.buildQueryParam(0,this.props.rowsPerPage));
  }

  buildQueryParam(newPage, newPageSize) {
    const prm = {
      startDate: this.state.startDate.getTime(),
      endDate: this.state.endDate.getTime(),
      page: newPage, //this.state.page - 1,
      size: newPageSize,
    }
    return Object.keys(prm).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(prm[k])}`).join('&');
  }

  startDateChange(date) {
    this.setState({startDate: date})
  }

  endDateChange(date) {
    this.setState({endDate: date})
  }

  handlePaginationChange = (event) =>{
    const val = parseInt(event.target.value, 10);
    this.props.fetchOperationData(this.buildQueryParam(0, val) );
  }

  formatDatetime = (epoch) => {
    return moment(new Date(epoch)).tz('Europe/Moscow').format('DD.MM.YYYY hh:mm');
  }

  handleChangePage = (event, newPage) => {
    this.props.fetchOperationData(this.buildQueryParam(newPage, this.props.rowsPerPage));
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} disableTypography={true}
                      title={
                        <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
                          Статистика <hr/>
                        </Typography>}
          />
          <CardContent>
            <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center">
              <Grid item xs={4}>
                <span>Начиная c :</span>
                <DatePicker className={classes.dataPicker} dateFormat="dd/MM/yyyy"
                            selected={this.state.startDate}
                            onChange={this.startDateChange}
                />
              </Grid>
              <Grid item xs={4}>
                <span>Заканчивая :</span>
                <DatePicker className={classes.dataPicker} dateFormat="dd/MM/yyyy"
                            selected={this.state.endDate}
                            onChange={this.endDateChange}
                />
              </Grid>

              <Grid item xs={4}>
                <Button size="small" variant="outlined" color="primary" className={classes.btnShow}
                        onClick={() => this.handleBtnShow()}>
                  Показать
                </Button>
              </Grid>

            </Grid>


              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Id операции</TableCell>
                    <TableCell align="left">Время операции</TableCell>
                    <TableCell align="left">Тип операции</TableCell>
                    <TableCell align="center">Статус</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.operationData.map(item => (
                    <TableRow key={item.id}>
                      <TableCell align="left">{item.operationNumber}</TableCell>
                      <TableCell align="left">{this.formatDatetime(item.createMoment)}</TableCell>
                      <TableCell align="left">{item.operationType.displayName}</TableCell>
                      <TableCell align="center">
                        <Typography style={{fontWeight: "bold"}}>
                          {item.operationState}
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelRowsPerPage = "Записей на страницу:"
                      labelDisplayedRows = {(obj) => {
                        var from = obj.from, to = obj.to, count = obj.count;
                        return "".concat(from, "-").concat(to, " из ").concat(count); }
                      }
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      colSpan={4}
                      count={this.props.totalSize}
                      rowsPerPage={this.props.rowsPerPage}
                      page={this.props.page}
                      SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handlePaginationChange}
                    />
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>

          </CardContent>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const operation = state.operation && state.operation.payload && state.operation.payload.data

  return {
    operationData: operation ? operation.content : [],
    totalSize: (operation && operation.totalElements) ? operation.totalElements : 0,
    page: (operation && operation.number) ? (operation.number) : 0,

    rowsPerPage: (operation && operation.size) ? operation.size : 10,
    //count: (operation && operation.totalElements) ? operation.totalElements : 0,
    //    fetchOpeationData: state.fetchOpeationData,

    // startDate: state.startDate,
    // endDate: state.endDate,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchOperationData: (payload) => { dispatch({type: FETCH_OPERATION, payload})},
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Statistic));
