import React, {PureComponent} from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import {Drawer, withStyles} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import {connect} from 'react-redux';
import * as types from 'constants/action-types'
import MenuItems from './main-menu-items'
import redirect from '../lib/redirect';
import ProgressAlert from 'components/progress-alert';
import IdxSnakebar from '../components/Snakebar';
import IdxAppBar from 'pages/main-app-bar';
import {JWT_STORAGE} from 'constants/app-global'
import ConfirmEmail from "./login/confirm-email";
import {history} from "../history";
//import Login from 'pages/login';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },

  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  toolbar: {
//    ...theme.mixins.toolbar,
    minHeight: 100,

  },

  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit *3,
  },
});

const APP_ENTRY_POINTS = ["/login", "/register", "/registr"];
const NO_LAYOUT_PAGES = ["/login", "/registr", "/register", "/resetPassword", "/enterNewPassword"];

class Main extends PureComponent {
  state = {
    selectedItemIndex: null
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenValue = searchParams.get('token');

    if(tokenValue) {
      localStorage.setItem(JWT_STORAGE, tokenValue);
    }

    const hasToken = localStorage.getItem(JWT_STORAGE) != null;
    if (!hasToken) {
      // entryPoints. страницы, которые можно открыть при запуске APP
      if (APP_ENTRY_POINTS.indexOf(this.props.location) === -1) {
        redirect('/login');
      }
    } else {
      this.props.handleLoginRequest();
    }
  }

  componentDidUpdate() {
    if (!this.props.isLoggedIn) return;
    if (this.props.location === '/login') {
      redirect("/");
      return;
    }
    //обновляем стаитстику в AppBar при переходах
   this.props.getStat();
  }

  drawerItems = (classes) => (

    <React.Fragment>
      <div className={classes.toolbar}/>
      {/* <Divider/> */}
      <List>
        <MenuItems
          selectedItemIndex={this.state.selectedItemIndex}
          handleListItemClick={this.handleListItemClick}
          location={this.props.location}
        />
      </List>
    </React.Fragment>
  );

  handleListItemClick = (selectedItemIndex, path, isNewWindow) => {
    const party = this.props.party;
    //this.setState({ selectedItemIndex });
    this.setState({selectedItemIndex: selectedItemIndex, selectedItemPath: path});

    //для перехода по меню требуется подтверждение по email
    if (party && party.emailConfirmed) {
      redirect(path);
    } else {
      //this.props.requeryParty({ lastPath: path })
      //this.props.requeryParty({ lastPath: path });
      this.props.fetchParty({ lastPath: path });
      history.replace({pathname: path});
      //redirect(path);
      //redirect('/confirmEmail');
    }

    if (this.props.isDrawerOpen) {
      this.handleCloseDrawer();
    }
  };

  handleCloseDrawer = () => {
    this.props.handleDrawerToggle({open: false});
  }

  handleDrawerToggle() {
    const newState = this.state.mobileOpen ? false : true;
    this.setState({mobileOpen: newState});
  }

  render() {
    const {classes, isDrawerOpen, isLoggedIn, location, party} = this.props;
//    console.log("main.render.isLoggedIn=" + isLoggedIn);

    const isSimplePage = NO_LAYOUT_PAGES.indexOf(location) > -1;

    //showConfirmEmail - показывать ли запрос поддтверждения почты
    const showConfirmEmail = party && !party.emailConfirmed && ["/register","/registr","/login", "/support"].indexOf(location) === -1;
    // console.log("showConfirmEmail=",showConfirmEmail, "isLoggedIn=", isLoggedIn);
    // console.log("children", this.props.children);
    return (

      <div className={classes.root}>
        <CssBaseline/>

        {isLoggedIn && <IdxAppBar/>}

        {(isLoggedIn) &&
        <nav className={classes.drawer} aria-label="mailbox folders">

          <Hidden implementation="css">
            <Drawer variant="temporary" anchor={'left'} open={isDrawerOpen}
                    onClose={() => this.handleCloseDrawer()}
                    classes={{paper: classes.drawerPaper,}}
                    ModalProps={{keepMounted: true,}}
            >
              {this.drawerItems(classes)}
            </Drawer>
          </Hidden>

          <Hidden smDown implementation="css">
            <Drawer classes={{paper: classes.drawerPaper,}} variant="permanent" open>
              {this.drawerItems(classes)}
            </Drawer>
          </Hidden>
        </nav>
        }

        {((isLoggedIn && !showConfirmEmail) || isSimplePage) &&
        <main className={classes.content}>
          <div className={classes.toolbar}/>
          {
            this.props.children
          }
        </main>
        }

        {/*если емейл не подтвержден*/}
        {(isLoggedIn && showConfirmEmail) &&
          <main className={classes.content}>
            <div className={classes.toolbar}/>
            <ConfirmEmail/>
          </main>
        }

        <ProgressAlert/>
        <IdxSnakebar/>

      </div>
    )
  }
}

function mapStateToProps(state) {
  //console.log("main.mapStateToProps----->", state);
  const party = state.party && state.party.payload && state.party.payload.data;
  const drawer = state.mobileDrawer && state.mobileDrawer.payload && state.mobileDrawer.payload;

  let isLoggedIn = false;
  if (party && party.id) {
    if (!party.isLegal) {
      isLoggedIn = true
    }
    ;
    if (party.isLegal && party.partyAttrId > 0) {
      isLoggedIn = true
    }
    ;
  }
//  console.log("mapStateToProps", state);
  return {
    location: state.router.location.pathname,
    party,
    isLoggedIn: isLoggedIn, //(party && party.id /*&& party.partyAttrId*/) ? true : false,
    isDrawerOpen: drawer && drawer.open ? true : false
  };
}


//Авторизоваться по токену (сохраняется в localStorege)
const mapDispatchToProps = dispatch => ({
  handleLoginRequest: () => dispatch({type: types.LOGIN}),
  handleDrawerToggle: (payload) => {
    dispatch({type: types.MOBILE_DRAWER, payload})
  },
  getStat: (payload) => {
    dispatch({type: types.GET_STAT, payload})
  },
  fetchParty: () => { dispatch({type: types.FETCH_PARTY}) },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Main));
