import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputMask from 'react-input-mask';

import * as types from 'constants/action-types';
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
  card: {
    maxWidth: 720,
    marginBottom: 20,
    margin: '0 auto',
  },
});

const errorClassName = {
  fontSize: '12px',
  color: red['500'],
  position: 'absolute',
  marginTop: '-7px',
};

class ProfilePerson extends PureComponent {
  constructor() {
    super();
    this.state = { personName: "", phone: "", phoneValid:true }
  }

  saveBtnClick = (e) => {
    e.preventDefault();
    if (!this.state.phoneValid) {
      this.props.showSnake({ snakeVariant: 'error', snakeMessage: { text: 'Некорректный номер телефона' }});
      return;
    }
    this.props.handleSaveButton(this.state);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.validate);
  }

  validate=()=>{
    if (this.state.phone !== "") {
      let s = this.state.phone.replace(/\D/g,'');
      this.setState({phoneValid: (s.length === 10 || s.length === 0)?true:false});
      // if (s.length == 10 || s.length == 0) {
      //   this.setState({phoneValid: true});
      // }
      // else {
      //   this.setState({phoneValid: false});
      // }
    }
  }

  componentDidMount() {
    if (this.props.party) {
      this.setState({
        personName: this.props.party.personName ? this.props.party.personName:"",
        phone: this.props.party.phone ? this.props.party.phone : ""
      }, this.validate)
    }
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader disableTypography={true} title={
          <div>
          <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
            Профиль
          </Typography>
          <Divider variant="middle"/>
          </div>
        }>
        </CardHeader>

        <CardContent className={this.props.classes.cardContent}>
          <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center">
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom align="right">ФИО</Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField name="personName" fullWidth margin="dense" variant="outlined" placeholder="Фамилия Имя Отчество" value={this.state.personName}
                         onChange={e => { this.handleChange(e); }}
              />
            </Grid>
            <Grid item xs={1}/>

            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom align="right">Телефон</Typography>
            </Grid>
            <Grid item xs={7}>
              <InputMask mask="(999) 999-99-99" value={this.state.phone} onChange={e => { this.handleChange(e); }}>
                {() => <TextField name="phone" fullWidth margin="dense" variant="outlined" placeholder="(999) 999-99-99"
                />  }
              </InputMask>
              {
                !this.state.phoneValid &&
                <div style={ errorClassName }> { "Укажите корректный номер телефона" } </div>
              }
            </Grid>
            <Grid item xs={1}/>

            <Grid item xs={12} style={{textAlign: "center"}}>
              <Button variant="contained" color="primary"  onClick={this.saveBtnClick}>
                Сохранить
              </Button>
            </Grid>


          </Grid>
        </CardContent>
      </Card>

    )
  }
}

function mapStateToProps(state) {
  let party = state.party && state.party.payload && state.party.payload.data;
//  console.log("profilePerson.mapStateToProps: ", party);
  return {
    party
  };
}

const mapDispatchToProps = dispatch => ({
  fetchParty: () => {dispatch({type: types.FETCH_PARTY})},
  handleSaveButton: (payload) => {dispatch({type: types.PROFILE_PERSON_UPDATE, payload})},
  showSnake: (payload)  => {dispatch({ type: types.SNAKE_OPEN, payload}) },

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfilePerson));
