import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/Forward';
import InputMask from 'react-input-mask';
import AutoComplete from './auto-complete'
import {PARTYATTR_UPDATE, REGISTR_STEP2} from 'constants/action-types';

const red300 = red['500'];

const styles = theme => ({
  ctrMargin: {
    margin: theme.spacing.unit,
    textTransform: 'none'
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  error: {
    right: 0,
    fontSize: '12px',
    color: red300,
    position: 'absolute',
    marginTop: '-25px',
  },
  errorClassName: {
    fontSize: '12px',
    color: red300,
    position: 'absolute',
    marginTop: '-7px',
  }

});

//const

class FieldsStep2 extends PureComponent {
  state = {
    manualUpdate: false,
    isFormNotValid: false
  }


  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
//    this.phoneRef = React.createRef();
    this.mainProps = {
      classes: props.classes,
      refs: {},
      openType: this.props.openType,
      validation: {}
    }

  }

  handleButton = () => {
    const attrs = {};
    const refs = this.mainProps.refs;

    Object.keys(refs).forEach(function (key) {
      attrs[key] = refs[key].value;
    });

    if (this.props.openType === "edit") {
      this.props.handleEditButton(attrs);
      //this.props.handleEditButton( { jsonAttr: JSON.stringify(attrs) });
    } else {
      this.props.handleRegistrButton(attrs);
      //this.props.handleRegistrButton({ jsonAttr: JSON.stringify(attrs) });
    }
  }

  handleSelectFinding = (selection) => {
    const refs = this.mainProps.refs;
    Object.keys(selection).forEach(function (key) {
      if (key in refs) {
        refs[key].value = selection[key];
      }
    })

    this.checkFormValidation();
  }

  setEditData = () => {
    if (this.props && this.props.partyAttr) {
      const attrs = this.props.partyAttr.data;
      if (attrs) {
        const refs = this.mainProps.refs;
        Object.keys(refs).forEach(function (key) {
          refs[key].value = attrs[key] ? attrs[key] : '';
        });
      }
    }
  }

  componentWillUpdate() {
    this.checkFormValidation();
  }

  handleChange = (event) => {
    this.checkFormValidation();
  }

  checkFormValidation() {
    const self = this;
    let notValid = false;
    const refs = this.mainProps.refs;

    Object.keys(refs).forEach(function (key) {
      //console.log(refs[key]);
      if ((refs[key].required || (refs[key].props && refs[key].props.required)) && self.checkEmpty(key, refs[key].value)) {
        notValid = true;
        self.mainProps.validation[key] = "Требуется обязательное заполнение";
      } else {
        self.mainProps.validation[key] = "";
      }
    });
    this.setState({manualUpdate: true, isFormNotValid: notValid});
    //this.forceUpdate();
  }

  componentDidMount() {
//    this.mainProps.refs['phone'] = this.phoneRef.current;

    if (!this.props.openType) {
      this.checkFormValidation();
    }
  }

  checkEmpty(fieldName, value) {
    let v = value.trim();
    if (fieldName === "inn") {
      v = v.replace(/_/g, "");
    }
    return v.length === 0;
  }

  render() {
    let title = "Реквизиты организации";

    if (this.props.openType === "edit") {
      title = "Реквизиты организации";
    }

    if (this.props.openType === "edit" && this.state.manualUpdate === false) {
      this.setEditData();
    }

    this.mainProps.onChange = this.handleChange;

    return (
      <Grid container spacing={32} direction="row" justify="flex-end" alignItems="center" style={{minWidth:340}} >
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" style={{marginLeft: 15, textAlign: 'center'}}>
            {title}
          </Typography>
          <Divider variant="middle"/>
        </Grid>
        <FIND  {...this.mainProps} onSelect={this.handleSelectFinding}/>

        <INN  {...this.mainProps} value={this.mainProps.refs['inn'] && this.mainProps.refs['inn'].value}/>
        <ShortName {...this.mainProps} />
        <Name {...this.mainProps} />
{/*        <OGRN {...this.mainProps} />*/}
        <KPP {...this.mainProps} />
{/*
        <Chief {...this.mainProps} />
        <Position {...this.mainProps} />
        <ActingBasis {...this.mainProps} />
        <PostalCode {...this.mainProps} />
        <Region {...this.mainProps} />
*/}
        <Address {...this.mainProps} />

{/*
        <Grid item xs={1}/><Grid item xs={10}>
        <hr/>
      </Grid>
        <Grid item xs={1}/>
        <Grid item xs={12}>
          <TextField name="personName" fullWidth margin="dense" variant="outlined" placeholder="Фамилия Имя Отчество" label="Контактное лицо"
                     InputLabelProps={{ shrink: true,}}
                     value={this.state.personName}
                     inputRef={el => {
                       if (el && el.name) this.mainProps.refs[el.name] = el
                     }}
                     onChange={e => {
                       this.mainProps.onChange(e)
                     }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputMask mask="(999) 999-99-99" onChange={this.mainProps.onChange} ref={this.phoneRef}>
            {() => <TextField name="phone" fullWidth margin="dense" variant="outlined" placeholder="(999) 999-99-99" label="Контактный телефон"
                              InputLabelProps={{ shrink: true,}} />
            }
          </InputMask>
        </Grid>
*/}
        <BtnContinue  {...this.mainProps} onClick={this.handleButton} isFormError={this.state.isFormNotValid}/>
      </Grid>
    )
  }
}

const FIND = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <AutoComplete refs={props.refs} onSelect={props.onSelect}/>
    </Grid>
  </React.Fragment>

class INN extends PureComponent {
  constructor(props) {
    super(props);
    this.innRef = React.createRef();
  }

  componentDidMount() {
    this.props.refs['inn'] = this.innRef.current;
  }

  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <React.Fragment>
            <InputMask mask="9999999999" maskChar={'_'} name="inn"  onChange={this.props.onChange} ref={this.innRef} required={true}>
              {(inputProps) =>
                <TextField name="inn" fullWidth margin="dense" variant="outlined" label="ИНН - 10 цифр" placeholder="10 цифр"
                           InputLabelProps={{ shrink: true,}}
                />
              }
            </InputMask>
            {
              this.props.validation["inn"] &&
              <div className={classes.errorClassName}> {this.props.validation["inn"]} </div>
            }
          </React.Fragment>
        </Grid>
      </React.Fragment>
    )
  }
}

const ShortName = (props) => {
  const {classes} = props;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12}>
        <TextField name="nameShort" fullWidth margin="dense" variant="outlined"
                   label="Краткое наименование" placeholder="Краткое наименование организации"
                   InputLabelProps={{ shrink: true,}}
                   inputRef={el => {
                     if (el && el.name) props.refs[el.name] = el
                   }}
                   onChange={e => {
                     props.onChange(e)
                   }}
                   required={true}
        />
        {
          props.validation["nameShort"] &&
          <div className={classes.errorClassName}> {props.validation["nameShort"]} </div>
        }
      </Grid>
    </React.Fragment>
  )
}

const Name = (props) => {
  const {classes} = props;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField name="nameFull" fullWidth margin="dense" variant="outlined" multiline rows="2" label="Полное наименование"
                   InputLabelProps={{ shrink: true,}}
                   placeholder="Полное наименование организации"
                   inputRef={el => {
                     if (el && el.name) props.refs[el.name] = el
                   }}
                   onChange={e => {
                     props.onChange(e)
                   }}
                   required={true}
        />
        {
          props.validation["nameFull"] &&
          <div className={classes.errorClassName}> {props.validation["nameFull"]} </div>
        }
      </Grid>
    </React.Fragment>
  )
}

const OGRN = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="ogrn" fullWidth margin="dense" variant="outlined" placeholder="ОГРН организации" label="ОГРН"
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>
  </React.Fragment>

const KPP = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="kpp" fullWidth margin="dense" variant="outlined" placeholder="КПП организации" label="КПП"
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>
  </React.Fragment>


/*
const Chief = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="chief" fullWidth margin="dense" variant="outlined" placeholder="Фамилия Имя Отчество" label="ФИО руководителя"
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>

  </React.Fragment>
*/

/*
const Position = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="position" fullWidth margin="dense" variant="outlined" placeholder="Должность руководителя" label="Должность руководителя"
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>
  </React.Fragment>
*/

/*
const ActingBasis = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="actingBasis" fullWidth margin="dense" variant="outlined" placeholder="Устава или иное основание" label ="Действует на основании"
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>
  </React.Fragment>
*/

/*
const PostalCode = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="postalCode" fullWidth margin="dense" variant="outlined" placeholder="Индекс" label="Индекс"
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>
  </React.Fragment>
*/

/*
const Region = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="regionName" fullWidth margin="dense" variant="outlined" placeholder="Наименование региона" label="Регион"
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>
  </React.Fragment>
*/

const Address = (props) =>
  <React.Fragment>
    <Grid item xs={12}>
      <TextField name="addressFull" fullWidth margin="dense" variant="outlined" placeholder="Полный адрес" label="Адрес" multiline rows={2}
                 InputLabelProps={{ shrink: true,}}
                 inputRef={el => {
                   if (el && el.name) props.refs[el.name] = el
                 }}
      />
    </Grid>
{/*    <Grid item xs={1}/>*/}
  </React.Fragment>

const BtnContinue = (props) => {
  const {classes, onClick} = props;
  return (
    <Grid item xs={12} style={{textAlign: "center"}}>
      <Button variant="contained" color="primary" className={classes.ctrMargin} onClick={onClick}
              disabled={props.isFormError}>
        {props.openType === "edit" ? "Сохранить" : "Продолжить"}
        {/*<ForwardIcon className={classes.rightIcon}/>*/}
      </Button>
    </Grid>
  )
}

FieldsStep2.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  let partyAttr = state.partyAttr && state.partyAttr.payload;

  return {
    partyAttr
  };
}

const mapDispatchToProps = dispatch => ({
  handleRegistrButton: (payload) => {
    dispatch({type: REGISTR_STEP2, payload})
  },
  handleEditButton: (payload) => {
    dispatch({type: PARTYATTR_UPDATE, payload})
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FieldsStep2));
