import {
  CHANGE_SUBSCRIPTION_SUCCEEDED,
  CLEAR_SUBSCRIPTION_PAYLOAD,
  CLOSE_WINDOW_CHANGE_SUBSCRIPTION,
  FETCH_USER_SUBSCRIPTION_SUCCEEDED,
  SHOW_WINDOW_CHANGE_SUBSCRIPTION,
} from '../constants/action-types';

const initialState = {
  payload: null,
  error: false,
  isShowInvoice: false,
  isShowInvoicePdf: false
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_SUBSCRIPTION_SUCCEEDED:
      return { ...state, error: false, payload, isShowInvoice: false, isShowInvoicePdf: false }

    case SHOW_WINDOW_CHANGE_SUBSCRIPTION:
      return { ...state, error: false, payload: {...state.payload, isShowWindowSelect: true, isShowInvoice: false, isShowInvoicePdf: false }}

    case CHANGE_SUBSCRIPTION_SUCCEEDED:
      return { ...state, error: false, payload: { ...payload, isShowWindowSelect: false, isShowInvoice: false, isShowInvoicePdf: true }}

    case CLOSE_WINDOW_CHANGE_SUBSCRIPTION:
      return { ...state, error: false, payload: { ...state.payload, isShowWindowSelect: false, isShowInvoice: false, isShowInvoicePdf: false}}

    case CLEAR_SUBSCRIPTION_PAYLOAD:
      return { ...state, error: false, payload: { isShowWindowSelect: false,
                isShowInvoice: false, isShowInvoicePdf: false }}

    default:
      return state
  }
};
