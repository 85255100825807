import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {ADD_VERIFY_TOKEN, REMOVE_VERIFY_TOKEN_SUCCEEDED} from 'constants/action-types'

class RedirectVerify extends PureComponent {
  componentDidMount() {
    this.props.addVerifyToken();
  }

  // openWindow(redis) {
  //   if (redis && redis.data) {
  //     const url = `${process.env.REACT_APP_VERIFY_URL}?token=${redis.data}`;
  //     window.location.href = url;
  //     //window.open(url, '_blank');
  //     this.props.removeRedisToken();
  //     redirect("/");
  //   }
  //   else {
  //     return <div></div>
  //   }
  // }

  componentDidUpdate() {
    const redis = this.props.redis;
    if (redis && redis.data) {
      const url = redis.data.host+"?token="+redis.data.token;
      this.props.removeVerifyToken();
      window.location.replace(url);
      //window.open(url, '_blank');
    }
  }

  render() {
    return (
      <React.Fragment/>
    )
  }
}

function mapStateToProps(state) {
  const redis = state.redis && state.redis.payload;
  return {
    redis
  };
}

const mapDispatchToProps = dispatch => ({
  addVerifyToken: (payload) => dispatch({ type: ADD_VERIFY_TOKEN, payload }),
  removeVerifyToken: () => dispatch({ type: REMOVE_VERIFY_TOKEN_SUCCEEDED })
})

export default connect(mapStateToProps, mapDispatchToProps)(RedirectVerify);
