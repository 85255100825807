import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';


import redirect from 'lib/redirect';
import {ADD_SUBSCRIPTION, CHANGE_SUBSCRIPTION, FETCH_AVAILABLE_SUBSCRIPTION} from 'constants/action-types';
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({
  root: {
  },
  btnChange: {
    textTransform: 'none',
    fontSize: '12px',
    marginLeft: '10px'
  },
  btnClose: {
    textTransform: 'none',
    fontSize: '13px',
    marginTop: '10px',
  },
});

/**
 * Форма просмотра активных подписок
 */
class SelectSubscription extends PureComponent {
  state = { isWindowOpen : true };

  componentDidMount() {
    this.props.fetchAvailableSubscription();
  }

  handleAddSubscription(id) {
    //const newSubsId = this.subscriptionAvailable.data[index].id;
    this.props.addSubscription({ newSubscriptionId: id })
  }

  componentDidUpdate() {
    if (this.props.subscriptionAvailable && this.props.subscriptionAvailable.goBack) {
      redirect('/subscription');
    }
  }

  render() {
    const { classes } = this.props;
    const subscriptionAvailable = this.props.subscriptionAvailable && this.props.subscriptionAvailable.data;

    return (
        <div className={ classes.root }>

              {
                subscriptionAvailable && subscriptionAvailable.length > 0 ?
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Тариф</TableCell>
                          <Hidden smDown>
                            <TableCell align="left">Описание</TableCell>
                          </Hidden>
                          <TableCell align="center">Стоимость</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subscriptionAvailable.map(item => (
                          <TableRow key={item.id}>
                            <TableCell align="center">{item.name}</TableCell>
                            <Hidden smDown>
                              <TableCell align="justify">{item.comment}</TableCell>
                            </Hidden>
                            <TableCell align="center">{item.price/100} руб.</TableCell>
                            <TableCell align="center">
                              <Button size="small" variant="outlined" color="primary" className={ this.props.classes.btnChange }
                                      onClick={ () => this.handleAddSubscription(item.id) }>
                                Выбрать
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>

                    </Table>
                  </div>
                  :
                  <div>
                    <Typography variant="body1" gutterBottom align="left" className={ classes.btnAdd }>
                      Нет доступных тарифов
                    </Typography>
                  </div>
              }

              <Typography variant="body1" gutterBottom align="center" style={{marginTop:15}}>
                <Link href="//iidx.ru/tarify" target="_blank"><small>Подробная информация по тарифам</small></Link>
              </Typography>

              <div>
              <Button size="medium" variant="contained" color="primary" className={ classes.btnClose }
                      onClick={ () => this.props.handleBtnCancel() }
              >
                Отмена
              </Button>
              </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
    const subscriptionAvailable = state.subscriptionAvailable
                            && state.subscriptionAvailable.payload

    const prm = state.router.location.state && state.router.location.state.params;

    return {
      subscriptionAvailable,
      params: prm
    };
  }

const mapDispatchToProps = dispatch => ({
  fetchAvailableSubscription: () => { dispatch({ type: FETCH_AVAILABLE_SUBSCRIPTION }) },
  changeSubscription: (payload) => { dispatch({ type: CHANGE_SUBSCRIPTION, payload }) },
  addSubscription: (payload) => { dispatch({ type: ADD_SUBSCRIPTION, payload }) }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectSubscription));
