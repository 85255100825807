import {FETCH_AVAILABLE_SUBSCRIPTION_SUCCEEDED} from '../constants/action-types';

const initialState = {
  payload: { isWindowOpen: false },
  error: false
}

export default (state = initialState, action) => {
const { type, payload } = action;

switch (type) {
  case FETCH_AVAILABLE_SUBSCRIPTION_SUCCEEDED:
    return { ...state, error: false, payload: { ...payload }}
  default:
    return state
  }
};
