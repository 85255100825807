import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import * as types from '../constants/action-types';
import formatErrorMsg from 'lib/format-error';
import {saveAs} from 'file-saver';


function* loadOffer(action) {
  if (action.type !== types.LOAD_OFFER) return;
  try {
    yield put({ type: types.LOADING_TRUE });

    switch(action.payload.type) {
      case 'html':
        const offerHtml = yield call(() => axios('/loadOffer', { method: 'POST'} ));
        yield put({type: types.OFFER_LOADED, payload: offerHtml});
        return;
      case 'pdf':
        // _responseType: 'blob'_ - самые важные слова здесь!!!
        const response = yield call(() => axios('/loadOfferPdf', { method: 'POST', responseType: 'blob'} ));
        saveAs(response.data, "idxPublicOffer.pdf");
        return;
      default:
        return;
    }
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}


// Sagas
function* watchLoadOffer() {
  yield takeEvery(types.LOAD_OFFER, loadOffer);
}

export {watchLoadOffer};
