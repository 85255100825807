import {LOADING_FALSE, LOADING_TRUE} from 'constants/action-types';

const initialState = {
  isLoading: false
}

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case LOADING_TRUE:
      return { /*...state, */ isLoading: true }
    case LOADING_FALSE:
      return { /*...state, */ isLoading: false }
    default:
      return state;
  }
};
