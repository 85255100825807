import {FETCH_ACCOUNT_INFO_SUCCEEDED} from '../constants/action-types';

const initialState = {
  payload: null,
  error: false
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ACCOUNT_INFO_SUCCEEDED:
      return { ...state, error: false, payload }
    default:
      return state
  }
};
