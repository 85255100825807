import {createBrowserHistory} from 'history';
import ym from 'react-yandex-metrika';
//import ReactGA from 'react-ga';

export const history = createBrowserHistory();

// const trackingId = "UA-000000000-1"; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId);
// ReactGA.set({
//   //userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
//   //???TODO
// })
//

// // Initialize google analytics page view tracking
history.listen(location => {
  //console.log("history.listen location.pathname", location.pathname);
  ym('hit', location.pathname);
  // ReactGA.set({ page: location.pathname }); // Update the user's current page
  // ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

//export default history;

