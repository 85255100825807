import * as types from '../constants/action-types';
import {
  FETCH_PARTY_ERROR,
  LOGOUT,
  REDIRECT_STEP2,
  REGISTR1_SUCCEEDED,
  REGISTR2_SUCCEEDED,
  REGISTR_ERROR
} from '../constants/action-types';

const initialState = {
  payload: null,
  error: false
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  //console.log("type="+type);
  //console.log(payload);

  switch (type) {
    case types.LOGIN_SUCCEEDED:
    case types.FETCH_PARTY_SUCCEEDED:
      return { ...state, error: false, payload };
    case FETCH_PARTY_ERROR:
      return { ...state, error: true, payload: null }
    case REGISTR_ERROR:
      return { ...state, error: true, payload: null }
    case REGISTR1_SUCCEEDED:
      return { ...state, error: false,  payload/*: {...state.payload, nextStep: payload.nextStep}*/}; //: { ...payload /*, nextStep: 2*/ } };
    case REGISTR2_SUCCEEDED:
      return { ...state, error: false, payload: { ...state.payload, nextStep: 3 }};
    case REDIRECT_STEP2:
      return { ...state, error: false,  payload: { ...state.payload, nextStep: 2 } };
    case LOGOUT:
      return { ...state, error: false, payload: null }
    default:
      return state
  }
};
