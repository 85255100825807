import React, {PureComponent} from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Login from 'pages/login';

class PrivateRoute extends PureComponent {

  render() {
    const { component:Component, isLoggedIn} = this.props;
//    console.log(this.props);
    const p = this.props;
    return (
      <Route  render={ () => (
        isLoggedIn === true
          ? <Component {...p} /> /*(emailConfirmed === true ? <Component {...p} /> : <ConfirmEmail {...p}/> )*/
          : <Login/>
      )} />
    )
  }
}

function mapStateToProps(state) {
  const party = state.party && state.party.payload && state.party.payload.data;

  return {
    isLoggedIn : (party && party.id /*&& party.partyAttrId*/) ? true : false,
    emailConfirmed : (party && party.id && (party.emailConfirmed)) ? true : false
  };
}

export default connect(mapStateToProps)(PrivateRoute);
