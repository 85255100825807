import * as types from "../constants/action-types";

const initialState = {
  payload: null,
  error: null
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_STAT_DONE:
      return { ...state, error: false, payload }
    default:
      return state;
  }
};
