
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const FETCH_PARTY = 'FETCH_PARTY';
export const FETCH_PARTY_SUCCEEDED = 'FETCH_PARTY_SUCCEEDED';
export const FETCH_PARTY_ERROR = 'FETCH_PARTY_ERROR';

export const SNAKE_OPEN = 'SNAKE_OPEN';
export const SNAKE_CLOSE = 'SNAKE_CLOSE';

export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';

export const REGISTR_STEP1 = 'REGISTR_STEP1';
export const REGISTR_STEP2 = 'REGISTR_STEP2';
export const REGISTR1_SUCCEEDED = 'REGISTR1_SUCCEEDED';
export const REGISTR2_SUCCEEDED = 'REGISTR2_SUCCEEDED';
export const REGISTR_ERROR = 'REGISTR_ERROR';
export const REDIRECT_STEP2 = 'REDIRECT_STEP2';

export const PARTY_UPDATE = 'PARTY_UPDATE';
export const PARTY_UPDATE_SUCCEEDED = 'PARTY_UPDATE_SUCCEEDED';
export const PARTYATTR_EDIT = 'PARTYATTR_EDIT';
export const FETCH_PARTYATTR = 'FETCH_PARTYATTR';
export const PARTYATTR_UPDATE = 'PARTYATTR_UPDATE';
export const PARTYATTR_SUCCEEDED = 'PARTYATTR_SUCCEEDED';
export const PARTYATTR_ERROR = 'PARTYATTR_ERROR';

export const FETCH_API_KEYS = 'FETCH_API_KEYS';
export const GEN_API_KEYS = 'GEN_API_KEYS';

export const EMAIL_SEND_RESET_PWD = 'EMAIL_SEND_RESET_PWD';
export const EMAIL_RESETPWD_SUCCEEDED = 'EMAIL_RESETPWD_SUCCEEDED';
export const SET_NEW_PWD = 'SET_NEW_PWD';
export const NEW_PWD_FAIL = 'NEW_PWD_FAIL';
export const NEW_PWD_SUCCEEDED = 'NEW_PWD_SUCCEEDED';

export const GET_DOC_PDF = 'GET_DOC_PDF';
export const COMPLETE_DOC_PDF = 'COMPLETE_DOC_PDF';

export const FETCH_USER_SUBSCRIPTION = 'FETCH_USER_SUBSCRIPTION';
export const FETCH_USER_SUBSCRIPTION_SUCCEEDED = 'FETCH_USER_SUBSCRIPTION_SUCCEEDED';
export const FETCH_AVAILABLE_SUBSCRIPTION = 'FETCH_AVAILABLE_SUBSCRIPTION';
export const FETCH_AVAILABLE_SUBSCRIPTION_SUCCEEDED = 'FETCH_AVAILABLE_SUBSCRIPTION_SUCCEEDED';
export const CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION';
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_SUCCEEDED = 'CHANGE_SUBSCRIPTION_SUCCEEDED'
export const SHOW_WINDOW_CHANGE_SUBSCRIPTION = 'SHOW_WINDOW_CHANGE_SUBSCRIPTION';
export const CLOSE_WINDOW_CHANGE_SUBSCRIPTION = 'CLOSE_WINDOW_CHANGE_SUBSCRIPTION';

export const FETCH_OPERATION = 'FETCH_OPERATION';
export const FETCH_OPERATION_SUCCEEDED = 'FETCH_OPERATION_SUCCEEDED';

export const ADD_VERIFY_TOKEN = 'ADD_VERIFY_TOKEN';
export const ADD_VERIFY_TOKEN_SUCCEEDED = 'ADD_VERIFY_TOKEN_SUCCEEDED';
export const REMOVE_VERIFY_TOKEN_SUCCEEDED = 'REMOVE_VERIFY_TOKEN_SUCCEEDED';

export const FETCH_ACCOUNT_INFO = 'FETCH_ACCOUNT_INFO';
export const FETCH_ACCOUNT_INFO_SUCCEEDED = 'FETCH_ACCOUNT_INFO_SUCCEEDED';

export const FETCH_BILL_OPERATION = 'FETCH_BILL_OPERATION';
export const FETCH_BILL_OPERATION_SUCCEEDED = 'FETCH_BILL_OPERATION_SUCCEEDED';

export const EMAIL_RESEND_CONFIRM = 'EMAIL_RESEND_CONFIRM';
export const EMAIL_RESEND_CONFIRM_SUCCEDED = 'EMAIL_RESEND_CONFIRM_SUCCEDED';

export const MOBILE_DRAWER = "MOBILE_DRAWER";
export const CLEAR_SUBSCRIPTION_PAYLOAD = 'CLEAR_SUBSCRIPTION_PAYLOAD';

export const FETCH_LOGOUT = "LOGOUT";

export const LOAD_OFFER = "LOAD_OFFER";
export const OFFER_LOADED = "OFFER_LOADED";

export const GET_STAT = "GET_STAT";
export const GET_STAT_DONE = "GET_STAT_DONE";

export const INVOICE_DOWNLOAD = "INVOICE_DOWNLOAD";

export const PROFILE_PERSON_UPDATE = "PROFILE_PERSON_UPDATE";

export const GET_PAYMENT_RESULT = "GET_PAYMENT_RESULT";
export const GET_PAYMENT_RESULT_DONE = "GET_PAYMENT_RESULT_DONE";
export const START_PAYMENT = "START_PAYMENT";
export const START_PAYMENT_DONE = "START_PAYMENT_DONE";

export const PARTY_CHANGE_PASSWORD="PARTY_CHANGE_PASSWORD";
export const PARTY_CHANGE_PASSWORD_DONE="PARTY_CHANGE_PASSWORD_DONE";

export const GET_FAQ="GET_FAQ";
export const GET_FAQ_DONE="GET_FAQ_DONE";
