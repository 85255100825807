import {SNAKE_CLOSE, SNAKE_OPEN} from 'constants/action-types';

const initialState = {
  payload: {
    snakeOpen: false,
    snakeVariant: 'info',
    snakeMessage: { text: '' }
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SNAKE_OPEN:
      payload.snakeOpen = true;
      return { ...state, payload }
    case SNAKE_CLOSE:
      return { ...state, payload: { snakeOpen: false, snakeVariant: 'info', snakeMessage: {} } }
    default:
      return state;
    }
};
