import React, {PureComponent} from "react";
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from '@material-ui/core';
import redirect from 'lib/redirect';
import AccountIcon from '@material-ui/icons/AccountCircleSharp';

const styles = theme => ({
  root: {
    display: 'flex',

  },
  paper: {
    marginRight: theme.spacing.unit *2,
  },
  popper: {
    position: 'absolute',
    marginTop: 45,
    right:50,
//    marginRight: 10
  }
});

class AccountButton extends PureComponent {
  state = { selectedItemIndex: null, open: false };

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleToggle() {
    this.setState({ open: true });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.root }>
        <Button
          aria-controls="menu-list-grow"
          aria-haspopup="true"
          variant="text"
          color="inherit"
          onClick={ this.handleToggle }
        >
          { this.props.user }
          <AccountIcon className={ classes.rightIcon }/>
        </Button>

        <Popper open={ this.state.open } className={ classes.popper } keepMounted
            transition disablePortal  placement={ "bottom" }>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={ this.handleClose }>
                  <MenuList>
                    <MenuItem onClick={ () => { redirect('/verify'); this.handleClose() } }>Проверки</MenuItem>
                    <MenuItem onClick={ this.props.handleBtnLogout }>Выход</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}


export default connect(null)(withStyles(styles)(AccountButton));
