import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import * as types from '../constants/action-types';
import formatErrorMsg from 'lib/format-error';
import qs from "qs";

function* getPaymentResult(action) {
  try {
    yield put({ type: types.LOADING_TRUE });
    const result = yield call(() => axios.post('/paymentResult', qs.stringify(action.payload)));
    yield put({type: types.GET_PAYMENT_RESULT_DONE, payload: result.data});
  }
  catch (e) {
    yield put({ type: types.SNAKE_OPEN, payload: {snakeVariant: 'error', snakeMessage: formatErrorMsg(e) }});
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* startPayment(action) {
  try {
    yield put({ type: types.LOADING_TRUE });
    const result = yield call(() => axios.post('/startPayment', qs.stringify(action.payload)));
    yield put({type: types.START_PAYMENT_DONE, payload: result.data});
  }
  catch (e) {
    yield put({ type: types.SNAKE_OPEN, payload: {snakeVariant: 'error', snakeMessage: formatErrorMsg(e) }});
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

export function* watchGetPaymentResult() {
  yield takeEvery(types.GET_PAYMENT_RESULT, getPaymentResult);
}

export function* watchStartPayment() {
  yield takeEvery(types.START_PAYMENT, startPayment);
}
