import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
//import { FETCH_API_KEYS, GEN_API_KEYS} from 'constants/action-types';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    maxWidth: 720,
    marginBottom: 20,
    margin: '0 auto',
    // minHeight: '635px'
  },

  cardContent: {
    textAlign: 'center',
  },
  btnMain: {
    marginBottom: 20
  },
  leftMargin: {
    marginLeft: 20
  },
});

class Api extends PureComponent {
  render() {
    return (
      <div >
        <Card className={ this.props.classes.card }>
          <CardHeader  disableTypography={true} title={
            <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
              API доступ
              <hr/>
            </Typography>
          }>
          </CardHeader>

          <CardContent className={this.props.classes.cardContent} >
            <h5>
              Для получения доступа по API, индивидуальных настроек сервиса и тарификации услуг отправьте заявку на адрес info@iidx.ru.
            </h5>
          </CardContent>
        </Card>
      </div>
    )
  }
}


/**
 * Форма изменения реквезитов
 */
/*
class Api extends PureComponent {
  componentDidMount() {
    //this.props.fetchApiKeys();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.root }>
        <Card className={ classes.card }>
          <CardContent>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" style={{ marginLeft: 15 }}>
                  API-доступ
                </Typography>
                <Divider variant="middle" />
              </Grid>

              <AccessKey value={ this.props.apiAccessKey } />
              <SecretKey value={ this.props.apiPrivateKey } />
              <BtnGenerateKey classes={ classes } onClick={ this.props.genApiKeys } />

              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" style={{ marginLeft: 15 }}>
                  Документация по API доступна по следующим ссылкам
                </Typography>
                <Divider variant="middle" />
              </Grid>

              <Doc apiName="API верификации" apiRef="https://iidx.ru/#rec66756629" classes={ classes } />
              <Doc apiName="API проверки" apiRef="https://iidx.ru/#rec66756629" classes={ classes } />
              <Doc apiName="API распознования" apiRef="https://iidx.ru/#rec66756629" classes={ classes } />
          </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const AccessKey = (props) =>
  <React.Fragment>
    <Grid item xs={4}>
      <Typography variant="body1" gutterBottom align="right">Access Key</Typography>
    </Grid>
    <Grid item xs={7}>
      <TextField
        name="accessKey"
        fullWidth
        readOnly
        margin="dense"
        variant="outlined"
        value={ props.value }
      />
    </Grid>
    <Grid item xs={1}/>
  </React.Fragment>

const SecretKey = (props) =>
  <React.Fragment>
    <Grid item xs={4}>
      <Typography variant="body1" gutterBottom align="right">Secret Key</Typography>
    </Grid>
    <Grid item xs={7}>
      <TextField
        name="accessKey"
        fullWidth
        readOnly
        margin="dense"
        variant="outlined"
        value={ props.value }
      />
    </Grid>
    <Grid item xs={1}/>
  </React.Fragment>

const Doc = (props) =>
  <React.Fragment>
    <Grid item xs={4}>
      <Typography variant="h6" gutterBottom align="left" className={ props.classes.leftMargin }>
        { props.apiName }
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography variant="body1" gutterBottom align="left">
        <a href={ props.apiRef } rel="noopener noreferrer" target="_blank"> { props.apiRef } </a>
      </Typography>
    </Grid>
  </React.Fragment>

const BtnGenerateKey = (props) => {
  const { classes, onClick } = props;

  return (
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Button variant="contained" color="primary" className={ classes.btnMain } onClick={ onClick }>
        Сгенерировать новые ключи
        <ForwardIcon className={ classes.rightIcon }/>
      </Button>
    </Grid>
  )
}

function mapStateToProps(state) {
  const party = state.party && state.party.payload && state.party.payload.data;

  return {
    apiAccessKey: party && party.apiAccessKey,
    apiPrivateKey: party && party.apiPrivateKey
  };
}

const mapDispatchToProps = dispatch => ({
  genApiKeys: () => { dispatch({ type: GEN_API_KEYS }) }
})
*/

//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Api));

export default connect(null, null)(withStyles(styles)(Api));
