import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from '../constants/action-types'


function* getFaq(action) {
  try {
    yield put({ type: types.LOADING_TRUE });
    const response = yield call(() => axios.post('/faq'));
    yield put({ type: types.GET_FAQ_DONE, payload: response.data });
  }
  catch (e) {
    yield put({ type: types.SNAKE_OPEN, payload: { snakeVariant: 'error', snakeMessage: formatErrorMsg(e) } });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
export function* watchGetFaq() {
  yield takeEvery(types.GET_FAQ, getFaq);
}

