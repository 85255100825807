import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* fetchUserActiveSubscription() {
  try {
    yield put({ type: types.LOADING_TRUE });

    const subscriptions = yield call(() => axios.post('/subscription'));
    yield put({ type: types.FETCH_USER_SUBSCRIPTION_SUCCEEDED, payload: subscriptions });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* fetchAvailableSubscription() {
  try {
    yield put({ type: types.LOADING_TRUE });

    const subscriptions = yield call(() => axios.post('/available-subscription'));
    yield put({ type: types.FETCH_AVAILABLE_SUBSCRIPTION_SUCCEEDED, payload: subscriptions });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

/**
 * Устарело, больше не используется
 */
function* changeSubscription(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const subscriptions = yield call(() => axios.post('/change-subscription', action.payload));

    //yield put({ type: types.SNAKE_OPEN, payload: { snakeVariant: 'info', snakeMessage: { text: 'Подписка успешно изменена' }}});

    yield put({ type: types.CHANGE_SUBSCRIPTION_SUCCEEDED, payload: subscriptions });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* addSubscription(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const subscriptions = yield call(() => axios.post('/add-subscription', action.payload));

    yield put({ type: types.SNAKE_OPEN, payload: { snakeVariant: 'info', snakeMessage: { text: 'Подписка успешно добавлена' }}});

    yield put({ type: types.CHANGE_SUBSCRIPTION_SUCCEEDED, payload: subscriptions });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
function* watchFetchUserSubsciption() {
  yield takeEvery(types.FETCH_USER_SUBSCRIPTION, fetchUserActiveSubscription);
}

function* watchFetchAvailableSubsciption() {
  yield takeEvery(types.FETCH_AVAILABLE_SUBSCRIPTION, fetchAvailableSubscription);
}

function* watchChangeSubsciption() {
  yield takeEvery(types.CHANGE_SUBSCRIPTION, changeSubscription);
}

function* watchAddSubsciption() {
  yield takeEvery(types.ADD_SUBSCRIPTION, addSubscription);
}

export { watchFetchUserSubsciption, watchFetchAvailableSubsciption, watchChangeSubsciption, watchAddSubsciption };
