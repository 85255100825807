import React, {PureComponent} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './snackbar-content-wrapper';
import redirect from 'lib/redirect';
import {connect} from 'react-redux';
import {LOGOUT, SNAKE_CLOSE} from 'constants/action-types';
import {JWT_STORAGE} from 'constants/app-global'

class IdxSnakebar extends PureComponent {
  componentDidUpdate() {
    const { snakeMessage, performLogout } = this.props;

    //если истекло время сессии (401) то перейти на форму логина
    if (snakeMessage.statusCode === 401) {
      console.log("401 received");
      performLogout();
    }
  }

  render() {
    const { snakeOpen, snakeVariant, snakeMessage, handleCloseSnake } = this.props;

    return(
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={ snakeOpen }
        autoHideDuration={ 8000 }
        onClose={ handleCloseSnake }
      >
      <SnackbarContentWrapper
        variant={ snakeVariant }
        message={ <span id="message-id">{ snakeMessage.text }</span> }
        onClose={ handleCloseSnake }
      />
      </Snackbar>
    )
  }
}

function mapStateToProps(state) {
  const payload = state.snakebar && state.snakebar.payload;
  //console.log(`snake payload = ${JSON.stringify(payload)}`)
  const { snakeOpen, snakeVariant, snakeMessage } = payload;

  return {
    snakeOpen,
    snakeVariant,
    snakeMessage,
  };
}

const mapDispatchToProps = dispatch => ({
  handleCloseSnake: () => { dispatch({ type: SNAKE_CLOSE }) },
  performLogout: () => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem(JWT_STORAGE);
    redirect('/login');
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(IdxSnakebar);
