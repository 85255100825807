import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/Forward';
import redirect from 'lib/redirect'
import logo from 'images/logo1.jpg';
import * as types from 'constants/action-types';
import {connect} from "react-redux";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
  },

  card: {
    maxWidth: 600,
    marginBottom: 20,
    margin: '0 auto'
  },

  ctrMargin: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  resetPwd: {
    textTransform: 'none',
    fontSize: "12px"
  },
  posCentered: {
    textAlign: 'center'
  },
  fields: {
    marginLeft: 5 * theme.spacing.unit,
    width: '80%'
  }
});

class LoginSubForm extends PureComponent {
  state = {showPassword: false}

  params = {
    username: "",
    password: "",
  }

  handleClickShowPassword = () => {
    this.setState(state => ({showPassword: !state.showPassword}));
  };

  handleChange = name => event => {
    this.params[name] = event.target.value;
  }

  handleLoginButton = () => {
    if (this.params.username === "" || this.params.password === "") {
      return;
    }
    this.props.handleLoginRequest(this.params);
  }

  handleResetPwdButton = () => {
    redirect('/resetPassword');
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={this.props.classes.root}>
        <Card className={this.props.classes.card}>
          <CardContent>

            <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center">
              <Grid item xs={12} className={classes.posCentered}>
                <img src={logo} width="150" alt={""}/>
              </Grid>

              <Grid item xs={12} className={classes.posCentered}>
                <Typography gutterBottom variant="h5" style={{marginLeft: 15}}>
                  ВХОД ДЛЯ КЛИЕНТОВ
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField className={classes.fields} margin="dense" variant="outlined" placeholder="EMAIL"
                           onChange={this.handleChange('username')}
                           onKeyPress={(ev) => {
                             if (ev.key === 'Enter') {
                               this.handleLoginButton();
                             }
                           }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField name="password" type={this.state.showPassword ? 'text' : 'password'} fullWidth
                           margin="dense" className={classes.fields} variant="outlined" placeholder="ПАРОЛЬ"
                           onChange={this.handleChange('password')}
                           onKeyPress={(ev) => {
                             if (ev.key === 'Enter') {
                               this.handleLoginButton();
                             }
                           }}
                           InputProps={{
                             endAdornment: (
                               <InputAdornment position="end">
                                 <IconButton aria-label="Toggle password visibility"
                                             onClick={this.handleClickShowPassword}>
                                   {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                 </IconButton>
                               </InputAdornment>
                             )
                           }}
                />
              </Grid>

              <Grid item xs={12} style={{textAlign: "center"}}>
                <Button variant="contained" color="default" className={classes.ctrMargin}
                        onClick={this.handleLoginButton}>
                  Войти
                  {/*<ForwardIcon className={classes.rightIcon}/>*/}
                </Button>
              </Grid>

              <Grid item xs={12} style={{textAlign: "center"}}>
                <Button className={classes.resetPwd} onClick={this.handleResetPwdButton}>
                  <span style={{borderBottom: '1px solid #1e2f45'}}> Забыли пароль? </span>
                </Button>
              </Grid>

              {/*<Grid item xs={12} style={{textAlign: "center", textTransform: 'none'}}>
                <Button variant="contained" color="secondary" className={classes.ctrMargin}
                        onClick={() => redirect('/register')}>
                  Попробовать бесплатно
                </Button>
              </Grid>*/}
            </Grid>
          </CardContent>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  handleLoginRequest: (payload) => dispatch({type: types.LOGIN, payload}),
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoginSubForm));
