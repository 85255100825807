import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
//import {GridList} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from '@material-ui/core';
//import CardActions from '@material-ui/core/CardActions';
import {connect} from 'react-redux';
//import Button from '@material-ui/core/Button';
//import ButtonBase from '@material-ui/core/ButtonBase';
import * as types from '../constants/action-types'
import "react-datepicker/dist/react-datepicker.css";
import {ExpandMore} from "@material-ui/icons";

const styles = theme => ({
  root: {
    background: '#eeeeee',
    position: relative
  },
  card: {
    margin: '0 auto',
  },

  cardActions: {
    padding: 30,
    justifyContent: 'center'
  },

  btnPerform: {
    textTransform: 'none',
    fontSize: '12px',
    marginLeft: '5px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },

});


class Support extends PureComponent {
  state = {isShowWindowSelect: true, amount: 3000, performRequestPdf: 0}

  componentDidMount() {
    this.props.getFaq();
  }

  render() {
    const {classes} = this.props;
    //console.log("faq", this.props.faq);
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} disableTypography={true}
                      title={
                        <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
                          Поддержка IDX<hr/>
                        </Typography>}
          />
          <CardContent>

            <Typography gutterBottom variant="h6" style={{textAlign: 'center', margin: 30}}>
              Часто задаваемые вопросы
            </Typography>


            {(this.props.faq) &&

            this.props.faq.map((item, index) => (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore/>}
                                       aria-controls="panel1a-content" id="panel1a-header">
                  <Typography className={classes.heading} > {item.question} </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    {item.answer}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
            }

            <Typography gutterBottom variant="h6" style={{textAlign: 'center', marginTop: 60}}>
              Контакты
            </Typography>
            <Typography gutterBottom variant="body1" style={{textAlign: 'center'}}>
              Если вы не нашли ответ на свой вопрос, вы можете отправить сообщение в службу поддержки IDX: help@iidx.ru
            </Typography>


          </CardContent>
        </Card>

      </div>
    )
  }
}


function mapStateToProps(state) {
  const faq = state.faq && state.faq.payload;
  return {faq};
}

const mapDispatchToProps = dispatch => ({
  getFaq: () => {
    dispatch({type: types.GET_FAQ})
  },
  // closeWinSubs: () => {     dispatch({type: types.CLOSE_WINDOW_CHANGE_SUBSCRIPTION})  },
  // clearSubscriptionPayload: () => {     dispatch({type: types.CLEAR_SUBSCRIPTION_PAYLOAD})  },
  // showErrorMessage: (message) => {  dispatch({type: types.SNAKE_OPEN, payload: {snakeVariant: 'error', snakeMessage: {text: message}}})  },
  // invoiceDownload: (amount) => {     dispatch({type: types.INVOICE_DOWNLOAD, payload: {amount: amount}})  },
  // startPayment: (amount) => {     dispatch({type: types.START_PAYMENT, payload: {amount: amount}})  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Support));
