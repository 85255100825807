import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* getStat(action) {
  try {
    yield put({ type: types.LOADING_TRUE });
    const stat = yield call(() => axios.post('/getStat', action.payload));
    yield put({ type: types.GET_STAT_DONE, payload: stat });
  }
  catch (e) {
    const payload = { snakeVariant: 'error', snakeMessage: formatErrorMsg(e) }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
// function* watchGetStat() {
//   yield takeEvery(types.GET_STAT, getStat);
// }

export function* watchGetStat() {
  yield takeEvery(types.GET_STAT, getStat);
};
