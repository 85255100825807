import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga';
import {history} from './history';
import snakebar from "reducers/snakebar";
import loading from 'reducers/loading';
import party from 'reducers/party';
import partyAttr from 'reducers/partyAttr';
import resetPwd from 'reducers/resetPwd';
import operation from 'reducers/operation';
import subscription from 'reducers/subscription';
import accountInfo from 'reducers/accountInfo';
import redis from 'reducers/redis';
import mobileDrawer from 'reducers/mobileDrawer'
import subscriptionAvailable from 'reducers/subscriptionAvailable';
import loadOffer from 'reducers/loadOffer';
import billOperation from 'reducers/billOperation';
import getStat from 'reducers/getStat';
import payment from 'reducers/payment';
import faq from 'reducers/faq';
import sagas from 'sagas';

// import { loadOffer, /*snakebar, *//*loading, party, partyAttr, resetPwd,*/ /*subscription, operation, *//*accountInfo,*/
//   /*redis, billOperation, *//*mobileDrawer, *//*subscriptionAvailable*/} from 'reducers';

const reduxDevToolsProps = { maxAge: 50 };
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevToolsProps)) || compose;



const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  snakebar,
  mobileDrawer,
  loadOffer,
  loading,
  party,
  partyAttr,
  resetPwd,
  accountInfo,
  operation,
  subscription,
  subscriptionAvailable,
  redis,
  billOperation,
  getStat,
  payment,
  faq
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  createRootReducer(history),
  undefined,
  composeEnhancers(
    applyMiddleware(...[
      sagaMiddleware,
      routerMiddleware(history)
    ])
  )
);

sagaMiddleware.run(sagas)

export default store;
