import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import paymentOk from 'images/paymentOk.png'
import paymentFail from 'images/paymentFail.png'
import paymentNotFound from 'images/paymentNotFound.png'
import * as types from 'constants/action-types';


const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    maxWidth: 720,
    marginBottom: 20,
    margin: '0 auto',
    // minHeight: '635px'
  },

  cardContent: {
    textAlign: 'center',
  },

});

class PaymentResult extends PureComponent {

  componentDidMount() {
    let paymentId = this.props.computedMatch.params.paymentId
    //console.log("paymentId", paymentId);

    this.props.getPaymentResult(paymentId);
  }

  render() {
    const {paymentResult} = this.props;
    //console.log("paymentResult", paymentResult);
    return (
      <div>
        <Card className={this.props.classes.card}>

          <CardContent className={this.props.classes.cardContent}>

            { (paymentResult === "PROCESSED") &&
              <div>
                <Typography gutterBottom variant="h5" style={{paddingTop: 16,}}>
                  Благодарим Вас!<br/>
                  Ваша оплата прошла успешно.<br/>
                  Средства на лицевом счете пополнены.<br/><br/>
                </Typography>

                <div style={{textAlign: "center", backgroundColor: 'white', padding: 20,}}>
                  <img src={`${paymentOk}`} alt="" style={{maxWidth: '40%', maxHeight: '40%', padding: '32',}}/>
                </div>
              </div>
            }

            { (paymentResult === "FAILED") &&
            <div>
              <Typography gutterBottom variant ="h5" style={{paddingTop: 16, }}>
                Упс... что-то пошло не так(((<br/>
                Оплата не прошла.<br/><br/>
              </Typography>
              <div style={{textAlign: "center", backgroundColor: 'white', padding: 20,}}>
                <img src={`${paymentFail}`}  alt="" style={{ maxWidth: '40%',maxHeight: '40%',padding: '32', }}/>
              </div>
            </div>
            }

            {(paymentResult === "CANCELLED") &&
            <div>
              <Typography gutterBottom variant ="h5" style={{paddingTop: 16, }}>
                Платеж отменен<br/><br/><br/>
              </Typography>
              <div style={{textAlign: "center", backgroundColor: 'white', padding: 20,}}>
                <img src={`${paymentNotFound}`} alt="" style={{ maxWidth: '40%',maxHeight: '40%',padding: '32', }}/>
              </div>
            </div>
            }

            { (paymentResult === "NOTFOUND") &&
            <div>
              <Typography gutterBottom variant ="h5" style={{paddingTop: 16, }}>
                Мы не нашли информацию по указанному платежу<br/><br/><br/>
              </Typography>
              <div style={{textAlign: "center", backgroundColor: 'white', padding: 20,}}>
                <img src={`${paymentNotFound}`} alt="" style={{ maxWidth: '40%',maxHeight: '40%',padding: '32', }}/>
              </div>
            </div>
            }

          </CardContent>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const paymentResult = state.payment && state.payment.payload && state.payment.payload.paymentResult ;

  return {
    paymentResult: paymentResult,
  };
}

const mapDispatchToProps = dispatch => ({
  getPaymentResult: (paymentId) => {
    dispatch({type: types.GET_PAYMENT_RESULT, payload: {paymentId: paymentId}})
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentResult));
