import {all, fork} from 'redux-saga/effects'
import {
  watchChangePassword,
  watchEmailResetPwd,
  watchFetchParty,
  watchFetchUser,
  watchGenerateKeys,
  watchResendEmailConfirm,
  watchSetNewPassword
} from './fetch-party';
import {watchPartyAttrFetch, watchPartyAttrUpdate, watchRegistrStep2} from './registr-step2';
import {watchRegistrStep1, watchUpdateParty} from './registr-step1';
import watchRequestOffer from './gen-offer-pdf';
import {
  watchAddSubsciption,
  watchChangeSubsciption,
  watchFetchAvailableSubsciption,
  watchFetchUserSubsciption
} from './subscription'
import {watchFetchUserOperation} from './operation';
import {watchFetchAccountInfo} from './fetch-account-info';
import {watchFetchBillOperation} from './fetch-bill-operation';
import {watchAddVerifyToken} from './redis';
import {watchLogout} from "./logout";
import {watchLoadOffer} from "./loadOffer"
import {watchGetStat} from "./getStat"
import {watchInvoiceDownload} from "./invoiceDownload"
import {watchProfilePersonUpdate} from "./profile"
import {watchGetPaymentResult, watchStartPayment} from "./payment"
import {watchGetFaq} from "./faq"

export default function* root() {
  yield all([
    fork(watchFetchUser),
    fork(watchFetchParty),
    fork(watchGenerateKeys),
    fork(watchRegistrStep1),
    fork(watchRegistrStep2),
    fork(watchPartyAttrFetch),
    fork(watchUpdateParty),
    fork(watchPartyAttrUpdate),
    fork(watchRequestOffer),
    fork(watchEmailResetPwd),
    fork(watchSetNewPassword),
    fork(watchResendEmailConfirm),
    fork(watchFetchUserSubsciption),
    fork(watchFetchAvailableSubsciption),
    fork(watchChangeSubsciption),
    fork(watchAddSubsciption),
    fork(watchFetchUserOperation),
    fork(watchAddVerifyToken),
    fork(watchFetchAccountInfo),
    fork(watchFetchBillOperation),
    fork(watchLogout),
    fork(watchLoadOffer),
    fork(watchGetStat),
    fork(watchInvoiceDownload),
    fork(watchProfilePersonUpdate),
    fork(watchGetPaymentResult),
    fork(watchStartPayment),
    fork(watchChangePassword),
    fork(watchGetFaq),

  ])
}
