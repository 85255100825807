import {EMAIL_RESETPWD_SUCCEEDED, NEW_PWD_FAIL, NEW_PWD_SUCCEEDED} from '../constants/action-types';

const initialState = {
  payload: { step: 0 },
  error: false
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_RESETPWD_SUCCEEDED:
      return { ...state, error: false, payload: { ...payload, step: 1 }}
    case NEW_PWD_FAIL:
      return { ...state, error: true, payload: { step: 0 }}
    case NEW_PWD_SUCCEEDED:
      return { ...state, error: false, payload: { ...payload, complete: true }}
    default:
      return state
  }
};
