import React, {PureComponent} from 'react';
import 'typeface-roboto';
import './App.css';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router/immutable';
import store from './store';
import {history} from './history';
import NotFound from 'pages/not-found';
import Registr, {PartyAttrEdit, PartyEdit} from 'pages/party';
import Login, {ConfirmEmail, EnterNewPassword, ResetPassword} from 'pages/login';
import UserSubscriptionView, {SelectSubscription} from 'pages/subscription';
import Statistic from 'pages/statistic.jsx'
import Payment from 'pages/payment'
import BillView from 'pages/billView.jsx'
import Api from "pages/api.jsx"
//import PdfWindow from 'pages/pdf-doc';
import RedirectVerify from 'pages/verify.jsx';
import PrivateRouter from 'components/private-router';
import Main from 'pages/main'
import Offer from "pages/offer.jsx"
import PaymentResult from "pages/paymentResult.js"
import {YMInitializer} from 'react-yandex-metrika';
import Support from "pages/support";
// import authStore from 'stores/authStore';
// import commonStore from 'stores/commonStore';
// import partyStore from 'stores/partyStore';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  palette: {
    secondary: {
      main: '#39df8d'
    }
  }
});

/*
const rootStore = {
  store,
  // authStore,
  // partyStore,
  // commonStore
};
*/



class App extends PureComponent {
  
  switchContent =
    <React.Fragment>
      <Switch>

        <Route path="/" exact >
          <Redirect to="/subscription" />
        </Route>
{/*        <Route path="/index.html" exact component={ Home }  />*/}
        <Route path="/login" exact component={ Login } />
        <Route path="/resetPassword" exact component={ ResetPassword } />
        <Route path="/enterNewPassword" exact component={ EnterNewPassword } />
        {/*<Route path="/registr" exact component={ Registr } />*/}
        {/*<Route path="/register" exact component={ Registr } />*/}

        <PrivateRouter path="/payment" exact component={ Payment }  />
        <PrivateRouter path="/payment/result/:paymentId"  component={ PaymentResult } />

        <PrivateRouter path="/verify/" exact component={ RedirectVerify } />
        <PrivateRouter path="/statistic/" exact component={ Statistic } />
        <PrivateRouter path="/party/view/" exact component={ PartyEdit } />
        <PrivateRouter path="/partyAttr/view/" exact component={ PartyAttrEdit } />
        <PrivateRouter path="/apiAccess" exact component={ Api } />
        <PrivateRouter path="/subscription" exact component={ UserSubscriptionView } />
        <PrivateRouter path="/user-bill" exact component={ BillView } />
        <PrivateRouter path="/select-subscription" exact component={ SelectSubscription } />
        <PrivateRouter path="/confirmEmail" exact component={ ConfirmEmail } />
        {/*<PrivateRouter path="/offer" exact component={ PdfWindow } title="Договор"/> } />*/}
        {/* <PrivateRouter path="/invoice" component={ PdfWindow }  title="Счет на оплату" /> */}
        <PrivateRouter path="/offer" exact component={ Offer } title="Договор" /> 
        <PrivateRouter path="/support" exact component={ Support } title="Поддержка" />
        <Route path="*" component={ NotFound } />
      </Switch>
    </React.Fragment>


  render() {
    // console.log("ya_id", process.env);

    return (
      <Provider store={ store }>

        <YMInitializer accounts={[parseInt(process.env.REACT_APP_YA_METRIKA_ID)]} options={{clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:false}} />

        <ConnectedRouter history={ history }>
          <MuiThemeProvider theme={ theme }>
            <Main children={ this.switchContent } />
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
