import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {relative} from 'path';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/Forward';
import Cookies from 'js-cookie';
import {SET_NEW_PWD} from 'constants/action-types';
import {JWT_STORAGE} from 'constants/app-global'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    maxWidth: 600,
    marginBottom: 20,
    margin: '0 auto',
    textAlign:'center'
  },
  cardHeader: {padding:30},
  btnMain: {
    marginBottom: 20
  },
});

/**
 * Форма ввода нового пароля (два раза)
 */
class EnterNewPassword extends PureComponent {
  state = { tokenExist: false }

  componentWillMount() {
    const token = Cookies.get(JWT_STORAGE);

    if (token) {
      localStorage.setItem(JWT_STORAGE, token);
      this.setState({ tokenExist: true })
    }

  }
  render() {
    const { classes, resetPwd } = this.props;

    return (
      <div className={ classes.root }>
        <Card className={ classes.card }>
          <CardHeader className={classes.cardHeader}
                      title={<Typography variant={'h5'} >Установите новый пароль<hr/></Typography>}
          />
          <CardContent>
            <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center" >
              {
                this.state.tokenExist ?
                (
                  resetPwd && resetPwd.complete ?
                    <Typography variant="h4" gutterBottom align="center">
                      { resetPwd.data }
                    </Typography>
                    :
                    <PasswordFields { ...this.props } />
                )
                :
                <Typography variant="h6" gutterBottom align="center">
                  Ошибка обработки запроса (возможно в браузере отключены cookies)
                </Typography>
              }
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

class PasswordFields extends PureComponent {
  state = {password1: null, password2: null, showPassword1: false, showPassword2: false };

  handleClickShowPassword2 = () => {
    this.setState(state => ({ showPassword2: !state.showPassword2 }));
  };
  handleClickShowPassword1 = () => {
    this.setState(state => ({ showPassword1: !state.showPassword1 }));
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  btnSetNewPassword = () => {
    this.props.handleSetNewPassword(this.state);
    /*
    if (this.state.password1 !== this.state.password2) {
      alert("Введеные пароли не совпадают, проверьте пожалуйста вводимые значения");
    } else {
      this.props.handleSetNewPassword(this.state);
    }
    */
  }

  render() {
    const { classes } = this.props;

    return(
      <React.Fragment>
        <Grid item xs={2}>
          <Typography variant="body1" gutterBottom align="right">Пароль</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            name="password1"
            type={ this.state.showPassword1 ? 'text' : 'password' }
            fullWidth
            margin="dense"
            style={{ width: '95%' }}
            variant="outlined"
            placeholder="Пароль"
            onChange={ this.handleChange('password1') }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={ this.handleClickShowPassword1 }
                  >
                  { this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={1}/>

        <Grid item xs={2}>
          <Typography variant="body1" gutterBottom align="right">Повторите пароль</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField name="password2" type={ this.state.showPassword2 ? 'text' : 'password' }
            fullWidth margin="dense" style={{ width: '95%' }}
            variant="outlined" placeholder="Пароль"
            onChange={ this.handleChange('password2') }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={ this.handleClickShowPassword2 }
                  >
                  { this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={1}/>

        <Grid item xs={12} style={{textAlign: "center" }}>
          <Button variant="contained" color="primary" className={ classes.btnMain } onClick={() => this.btnSetNewPassword() } >
            Установить пароль
            <ForwardIcon className={ classes.rightIcon }/>
          </Button>
        </Grid>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const resetPwd = state.resetPwd && state.resetPwd.payload;

  return {
    resetPwd
  };
}

const mapDispatchToProps = dispatch => ({
  handleSetNewPassword: (payload) => dispatch({ type: SET_NEW_PWD, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EnterNewPassword));
