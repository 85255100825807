import React, {PureComponent} from 'react';
import {Button, Card, CardContent, CardHeader, Typography} from '@material-ui/core';
import {relative} from 'path';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import redirect from 'lib/redirect';
import {EMAIL_RESEND_CONFIRM} from 'constants/action-types';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    //background: '#eeeeee',
    position: relative
  },
  card: {
    maxWidth: 720,
    //    marginBottom: 20,
    margin: '0 auto',

  },
  // cardHeader: {
  //   backgroundColor: '#bbdefb'
  // },
  cardContent: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  btnMain: {
    marginTop: 40,
    marginBottom: 20,
  },
/*  ctrMargin: {
    marginTop: 20,
    maxWidth: 400,
    textTransform: 'none',
  },*/
});

class ConfirmEmail extends PureComponent {
  componentDidUpdate() {
    const party = this.props.party;

    if (party && party.data.emailConfirmed && party.lastPath) {
      redirect(party.lastPath);
    }
  }


  render() {
    const { classes } = this.props;

    return(
      <div className={ classes.root }>
        <Card className={ classes.card }>
          <CardHeader className={ classes.cardHeader }   disableTypography={ true }
            title={
              <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
              Подтверждение email
              <hr/>
              </Typography>}
          />
          <CardContent className={ classes.cardContent }>
            Для полноценной работы с порталом требуется подтвердить свой email

            <Button variant="contained" color="primary" className={classes.btnMain} onClick={ () => this.props.resendEmailConfirm() } >
              Повторная отправка кода подтерждения
            </Button>
            <Typography gutterBottom style={{textAlign: 'center',  fontSize: "small"}}>
              Если у вас возникли проблемы с подтверждением email, вы можете обратиться в службу поддержки IDX по адресу help@iidx.ru
            </Typography>
          </CardContent>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const party = state.party && state.party.payload;
  //console.log(`confirm-email, mapStateToProps, confEm= ${party.data.emailConfirmed}`)

  return {
    party
  };
}

const mapDispatchToProps = dispatch => ({
  resendEmailConfirm: (payload) => dispatch({ type: EMAIL_RESEND_CONFIRM, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfirmEmail));
