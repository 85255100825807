import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VerifyIcon from '@material-ui/icons/TrackChanges';
import ApiIcon from '@material-ui/icons/Settings';
import StatIcon from '@material-ui/icons/TrendingUp';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PictireIncon from '@material-ui/icons/PictureInPicture'
import ShoppingIcon from '@material-ui/icons/ShoppingCart'
import AccountIcon from '@material-ui/icons/SupervisorAccount'
import PartyAttrIcon from '@material-ui/icons/ContactMail'
import OfferIcon from '@material-ui/icons/Description'
import HelpIcon from '@material-ui/icons/Help'

/**
 * Левое меню
 */
const MenuItems = [
  { text: 'Проверки', icon: <VerifyIcon/>, path: '/verify' },
  { text: 'Лицевой счет', icon: <PictireIncon/>, path: '/subscription' },
  { text: 'Пополнение', icon: <CreditCardIcon/>, path: '/payment' },
  //{ text: 'Статистика', icon: <StatIcon/>, path: '/statistic', divider: true },
  { text: 'Статистика', icon: <StatIcon/>, path: '/statistic' },
  { text: 'Списания', icon: <ShoppingIcon/>, path: '/user-bill' },
  { text: 'Учетная запись', icon: <AccountIcon/>, path: '/party/view' },
  { text: 'Профиль', icon: <PartyAttrIcon/>, path: '/partyAttr/view' },
  { text: 'API доступ', icon: <ApiIcon/>, path: '/apiAccess' },
/*  { text: 'Договор pdf', icon: <OfferIcon/>, isNewWindow: 1, path: '/offer' },*/
  { text: 'Договор', icon: <OfferIcon/>,  path: '/offer' },
  { text: 'Поддержка', icon: <HelpIcon/>,  path: '/support' },
]

export default (props) => (
  MenuItems.map((item, index) => (
    <ListItem button
      key={ `menu-item-${index}` }
      divider={ item.divider }
      selected= {props.location === item.path}//{ props.selectedItemIndex === index }
      onClick={ () => props.handleListItemClick(index, item.path, item.isNewWindow) }
    >
      { item.icon &&
        <ListItemIcon>
          { item.icon }
        </ListItemIcon>
      }

      <ListItemText primary={ item.text } />
    </ListItem>
  ))
)
