import {MAX_SUGGESTION_COUNT} from 'constants/app-global'

/**
 * Преобразование полученных данных от DaData к собственному формату
 */
export default (data) => {
  const result = [];

  data.suggestions.slice(0, MAX_SUGGESTION_COUNT).forEach((item, index) => {
    const r = {};
    const ref = item.data;

    r.nameFull = ref.name.full_with_opf;
    r.nameShort = ref.name.short_with_opf;
    r.inn = ref.inn;
    r.kpp = ref.kpp;
    r.ogrn = ref.ogrn;

    r.chief = ref.management ? ref.management.name : "";
    r.position = ref.management ? ref.management.post : "";
    r.status = ref.state.status;

    r.postalCode = ref.address.data ? ref.address.data.postal_code : "";
    r.regionName = ref.address.data ? ref.address.data.region : "";
    r.addressSource = ref.address.data ? ref.address.data.source : "";
    r.addressFull = ref.address.value;

    r.value = item.value;
    r.label = r.inn;
    r.key = `${r.inn}-${r.value}`;

    result.push(r);
  });

  return result;
}
