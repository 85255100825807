import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'
import {JWT_STORAGE} from 'constants/app-global'

function* partyAdd(action) {
  try {
    localStorage.removeItem(JWT_STORAGE);
    yield put({ type: types.LOADING_TRUE });

    const resp = yield call(() => axios.post('/party/addOld', action.payload));
    localStorage.setItem(JWT_STORAGE, resp.data.token);
//    yield put({ type: types.REGISTR1_SUCCEEDED, payload: {nextStep: resp.data.nextStep }});

    //console.log(resp.data);
    //теперь получить данные по добавленному юзеру
    const user = yield call(() => axios.post('/party/viewOld'));
    // yield put({ type: types.FETCH_PARTY_SUCCEEDED, payload: user });
    let payload = user;
    payload.nextStep = resp.data.nextStep;
    yield put({ type: types.REGISTR1_SUCCEEDED, payload: payload });

  }
  catch (e) {
    localStorage.removeItem(JWT_STORAGE);
    yield put({ type: types.REGISTR_ERROR });
    yield put({ type: types.SNAKE_OPEN, payload: { snakeVariant: 'error', snakeMessage: formatErrorMsg(e)} });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* partyUpdate(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const response = yield call(() => axios.post('/party/update', action.payload));

    yield put({ type: types.PARTY_UPDATE_SUCCEEDED, payload: response });
    yield put({ type: types.SNAKE_OPEN, payload: { snakeVariant: 'info', snakeMessage: { text: 'Учетная запись успешно изменена' }}});
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
function* watchRegistrStep1() {
  yield takeEvery(types.REGISTR_STEP1, partyAdd);
}
function* watchUpdateParty() {
  yield takeEvery(types.PARTY_UPDATE, partyUpdate);
}

export { watchRegistrStep1, watchUpdateParty };
