import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {relative} from 'path';
import FieldsStep1 from './fields-step1';
import FieldsStep2 from './fields-step2';
import FieldsStep3 from './fields-step3';
import NotFound from 'pages/not-found';
import {connect} from 'react-redux';
import PartyEdit from './party-edit';
import PartyAttrEdit from './party-attr-edit';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    maxWidth: 600,
    marginBottom: 10,
    margin: '0 auto',
    //minHeight: 580
  },
  cardHeader: {
    backgroundColor: '#bbdefb'
  },
  btnMain: {
    marginBottom: 20
  },
});

/**
 * Форма регистрации
 */
class RegistrForm extends PureComponent {
  renderStep(regStep) {
    switch (regStep) {
      case 1:
        return <FieldsStep1 />;
      case 2:
        return <FieldsStep2 />;
      case 3:
        return <FieldsStep3 />;
      default:
        return <NotFound/>;
    }
  }

  /*
   * Рендеринг
   */
  render() {
    const { classes, party} = this.props;
    let regStep = 1;
    if (party.nextStep){
      regStep = party.nextStep;
    }
    else if (party && party.data){
      regStep = party.data.partyAttrId ? 3 : 2;
    }

    return (
      <div className={ classes.root }>
        <Card className={ classes.card }>
          <CardContent>
            { this.renderStep(regStep) }
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let party = state.party  && state.party.payload;
  if (!party) {
    party = { nextStep: 1 }
  }
  return {
    party
  };
}

export default connect(mapStateToProps)(withStyles(styles)(RegistrForm));
export { PartyEdit, PartyAttrEdit }
