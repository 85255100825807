import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import * as types from '../constants/action-types'
import paymentBank from 'images/paymentBank.png'
import paymentCard from 'images/paymentCard.png'
import paymentCode from 'images/paymentCode.png'

const styles = theme => ({
  root: {
    background: '#eeeeee',
    position: "relative"
  },
  card: {
    maxWidth: 720,
//    marginTop: 80,
    margin: '0 auto',
    textAlign: 'center',
  },

  cardActions: {
    padding: 30,
    justifyContent: 'center'
  },

  btnPerform: {
    textTransform: 'none',
    fontSize: '12px',
    marginLeft: '5px'
  },
  invoiceButton: {
    // textTransform: 'none',
    // fontSize: '12px',
    // float: 'right'
  },


});




class Payment extends PureComponent {
  state = {isShowWindowSelect: true, amount: 3000, performRequestPdf: 0}

  images = [
    {
      id: 1,
      url: `${paymentBank}`,
      title: 'Банковским платежом по выставленному счету',
      width: '30%',
      enabled: true
    },

    {
      id: 2,
      url: `${paymentCard}`,
      title: 'Банковской картой, электронными деньгами',
      width: '30%',
      enabled: true
    },

    {
      id: 3,
      url: `${paymentCode}`,
      title: 'По промокоду',
      width: '30%',
      enabled: false
    },
  ];


  componentDidMount() {
    if (this.props.isShowInvoicePdf) {
      const remainAmount = this.calcInvoiceRemainAmount();
      this.setState({amount: remainAmount})
      this.props.clearSubscriptionPayload();
    }
    else {
      this.setState({amount: 3000})
    }
  }

  componentDidUpdate() {
    if (this.props.paymentUrl) {
      window.location.replace(this.props.paymentUrl);
    }
  }

  getSubscriptionPrice() {
    const s = this.props.userSubscription;
    if (s && s.length) {
      const l = s[s.length - 1];
      return (l.serviceSpec.price / 100).toFixed(0);
    }
    return 0;
  }

  calcInvoiceRemainAmount() {
    let b = this.props.accountInfo && this.props.accountInfo.accountBalance &&
      this.props.accountInfo.accountBalance / 100;
    if (!b) {
      b = 0;
    }
    const sp = parseFloat(this.getSubscriptionPrice());
    return Math.ceil(b < sp ? (sp - b) : 0);
  }

  setAmount = e => {
    const val = e.target.value;
    const v = val.match(/\d/g);
    this.setState({amount: v == null ? '' : v.join("")})
  }

  onClick = (e, idx) => {
    e.preventDefault();
    if (idx === 1) {
      this.props.invoiceDownload(this.state.amount);
    }
    else if (idx === 2) {
      this.props.startPayment(this.state.amount);
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        {
          <div className={classes.root}>
            <Card className={classes.card}>
              <CardHeader className={classes.cardHeader} disableTypography={true}
                          title={
                            <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
                              Пополнение лицевого счета<hr/>
                            </Typography>}
              />
              <CardContent>

                <TextField
                  className={this.props.classes.textField} label="Введите сумму счета, руб" margin="dense" variant="outlined" placeholder="Сумма в рублях"
                  onChange={this.setAmount}
                  value={this.state.amount}
                />
              </CardContent>
            </Card>


            <Paper square={true} elevation={0}
                   style={{background: "#eee", marginTop: 100, justifyContent: 'center', textAlign: 'center',}}>

              <Grid container spacing={8}>
                {this.images.map((image, index) => (

                  <Grid key={image.id} item xs={12} sm={12} md={4}>

                    <Link href="" onClick={(e) => this.onClick(e, image.id)}
                          style={{pointerEvents: image.enabled ? '' : 'none'}}>
                      <div style={{textAlign: "center", backgroundColor: 'white', padding: 20, height: 270}}>
                        <Typography gutterBottom style={{
                          paddingTop: 16,
                          fontSize: '1.1rem',
                          opacity: image.enabled ? '1' : '0.2'
                        }}> {image.title} </Typography>
                        <img src={image.url} alt="" style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          paddingBottom: '32',
                          opacity: image.enabled ? '1' : '0.2'
                        }}/>
                      </div>
                    </Link>

                  </Grid>
                ))}
              </Grid>
            </Paper>
          </div>
        }
      </React.Fragment>
    )
  }
}

Payment.propTypes = {
  redirectUrl: PropTypes.string
};

Payment.defaultProps = {
  redirectUrl: '/',
};

function mapStateToProps(state) {
  const userSubscription = state.subscription && state.subscription.payload;
  const accountInfo = state.accountInfo && state.accountInfo.payload;
  const paymentUrl = state.payment && state.payment.payload && state.payment.payload.paymentUrl;
  return {
    userSubscription: userSubscription && userSubscription.data,
    isShowInvoicePdf: userSubscription && userSubscription.isShowInvoicePdf ? true : false,
    accountInfo: accountInfo && accountInfo.data,
    paymentUrl: paymentUrl,

  };
}

const mapDispatchToProps = dispatch => ({
  closeWinSubs: () => {     dispatch({type: types.CLOSE_WINDOW_CHANGE_SUBSCRIPTION})  },
  clearSubscriptionPayload: () => {     dispatch({type: types.CLEAR_SUBSCRIPTION_PAYLOAD})  },
  showErrorMessage: (message) => {  dispatch({type: types.SNAKE_OPEN, payload: {snakeVariant: 'error', snakeMessage: {text: message}}})  },
  invoiceDownload: (amount) => {     dispatch({type: types.INVOICE_DOWNLOAD, payload: {amount: amount}})  },
  startPayment: (amount) => {     dispatch({type: types.START_PAYMENT, payload: {amount: amount}})  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Payment));
