import {call, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import * as types from 'constants/action-types';
import {JWT_STORAGE} from 'constants/app-global';
import redirect from "../lib/redirect";

function* logout(action) {
  try {
    yield call(() => axios.post('/verifyLogout', action.payload));
  }
  catch (e) {  }

  localStorage.removeItem(JWT_STORAGE);
  console.log('redicrect to new portal');
  window.location.replace('https://online.iidx.ru/logout');
}

// Sagas
function* watchLogout() {
  yield takeEvery(types.LOGOUT, logout);
}

export { watchLogout };
