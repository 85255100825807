import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* partyAttrAdd(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const partyAttrId = yield call(() => axios.post('/partyAttr/add', action.payload));

    yield put({ type: types.REGISTR2_SUCCEEDED, payload: partyAttrId });

    //получить данные по добавленному юзеру (чтобы показать левое меню)
    const user = yield call(() => axios.post('/party/viewOld'));
    yield put({ type: types.FETCH_PARTY_SUCCEEDED, payload: user });
  }
  catch (e) {
    yield put({ type: types.REGISTR_ERROR });

    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* partyAttrUpdate(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const response = yield call(() => axios.post('/partyAttr/update', action.payload));

    yield put({ type: types.PARTY_UPDATE_SUCCEEDED });
    yield put({ type: types.SNAKE_OPEN, payload: {snakeVariant: 'info', snakeMessage: { text: response.data }}});
  }
  catch (e) {
    yield put({ type: types.REGISTR_ERROR });

    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* fetchPartyAttr() {
  try {
    yield put({ type: types.LOADING_TRUE });
    const partyAttr = yield call(() => axios.post('/partyAttr/view', null));

    yield put({ type: types.PARTYATTR_SUCCEEDED, payload: partyAttr });
  }
  catch (e) {
    yield put({ type: types.PARTYATTR_ERROR });

    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
function* watchRegistrStep2() {
  yield takeEvery(types.REGISTR_STEP2, partyAttrAdd);
}
function* watchPartyAttrFetch() {
  yield takeEvery(types.FETCH_PARTYATTR, fetchPartyAttr);
}
function* watchPartyAttrUpdate() {
  yield takeEvery(types.PARTYATTR_UPDATE, partyAttrUpdate);
}

export { watchRegistrStep2, watchPartyAttrFetch, watchPartyAttrUpdate };
