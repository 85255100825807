import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
import FieldsStep2 from './fields-step2';
import ProfilePerson from '../../pages/profilePerson.jsx';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {FETCH_PARTYATTR} from 'constants/action-types'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    maxWidth: 720,
    marginBottom: 20,
    margin: '0 auto',
    minHeight: '634px'
  },

  btnMain: {
    marginBottom: 20
  },
});

/**
 * Форма изменения реквизитов
 */
class RegistrAttrForm extends PureComponent {
  componentDidMount() {
    if (this.props.party && this.props.party.data.isLegal) {
      this.props.fetchPartyAttr();
    }
  }

  render() {
    const { classes, party } = this.props;

    //console.log("isLegal", party.data.isLegal);

    return (
      (party && party.data.isLegal) ?

        <div className={ classes.root }>
          <ProfilePerson/>

          <Card className={ classes.card }>
            <CardContent>
              <FieldsStep2 openType='edit'/>
            </CardContent>
          </Card>


        </div>
        :

        <ProfilePerson/>

    );
  }
}

function mapStateToProps(state){
  let party = state.party  && state.party.payload ;
  return {
    party
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPartyAttr: () => { dispatch({ type: FETCH_PARTYATTR }) }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegistrAttrForm));
