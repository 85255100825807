import React, {PureComponent} from 'react';
import {Typography} from '@material-ui/core';

class FieldsStep3 extends PureComponent {
  render() {
    return(
      <div>
        <Typography gutterBottom variant="h5">
          Вы успешно зарегистрированы.
          На Ваш email отправлено письмо для подтверждения регистрации.
        </Typography>
      </div>
    )
  }
}

export default FieldsStep3;
