import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
import {connect} from 'react-redux';
import {
  Card,
  CardActions,
  CardContent, DialogContent, DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import redirect from 'lib/redirect';
import * as types from '../../constants/action-types';
import SelectSubscription from './select-subscription';
import {JWT_STORAGE} from "../../constants/app-global";
import Dialog from "@material-ui/core/Dialog";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    marginBottom: 20,
    margin: '0 auto',
  },
  cardActions: {
    padding: 30,
  },
  oneSubs: {
    backgroundColor: '#cfd8dc',
    marginTop: '15px'
  },
  leftMargin: {
    marginLeft: '15px'
  },
  btnChange: {
    textTransform: 'none',
    fontSize: '12px',
    marginLeft: '20px'
  },
  btnAdd: {
    textTransform: 'none',
    fontSize: '14px',
    marginTop: '20px',
    marginLeft: '5px'
  },

});

/**
 * Цвет тектового значения текущего состояния
 */
const colorByState = (stateId) => {
  switch (stateId) {
    case 10:
      return '#7986cb';
    case 20:
      return '#3949ab';
    case 90:
      return 'red'; //'#4e342e';
    case 100:
      return 'green'; //'#b71c1c'
    case 900:
      return '#4e342e';
    case 999:
      return 'black';
    default:
      return 'black';
  }
}

/**
 * Форма просмотра активных подписок
 */
class SubscriptionView extends React.Component {
  state = { selectedSubscrptionId: 0, isShowPdf: false }

  componentDidMount() {
    if (localStorage.getItem(JWT_STORAGE) != null) {
      this.props.fetchActiveSubscription();
      this.props.fetchAccountInfo();
    }
  }

  handleChangeSubscription(index) {
    const data = this.props.userSubscription;
    if (data) {
      const selectedSubscrptionId = data[index].id;
      this.setState({ selectedSubscrptionId });
      this.props.showWindowSelect();
    } else {
      console.log("system error");
    }
  }

  handleAddSubscription() {
    this.setState({ selectedSubscrptionId: 0 });
    this.props.showWindowSelect();
  }

  handleBtnCancel() {
    this.props.closeWindowSelect();
  }

  componentWillUpdate() {
    //console.log("willUpdate, isShowInvocePdf", this.props.isShowInvoicePdf)
  }

  componentDidUpdate() {
    //console.log("didUpdate, isShowInvocePdf", this.props.isShowInvoicePdf)
    const remainAmount = this.calcInvoiceRemainAmount();

    if (this.props.isShowInvoicePdf && remainAmount > 0) {
      redirect("/payment")
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isShowInvoicePdf === true && nextProps.isShowInvoicePdf === true) {
      return false;
    }
    else {
      return true;
    }
  }

  getSubscriptionPrice() {
    const s = this.props.userSubscription;
    if (s && s.length) {
      const l = s[s.length - 1];
      return (l.serviceSpec.price / 100).toFixed(0);
    }
    return 0;
  }

  calcInvoiceRemainAmount() {
    let b =  this.props.accountInfo && this.props.accountInfo.accountBalance &&
             this.props.accountInfo.accountBalance / 100;

    if (!b) {
      b = 0;
    }

    const sp = parseFloat(this.getSubscriptionPrice());

    return Math.ceil(b < sp ? (sp - b) : 0);
  }

  render() {
    const { classes, accountInfo } = this.props;
    const accountBalance = accountInfo && (accountInfo.accountBalance / 100).toFixed(2);
    const accountNumber = accountInfo && (accountInfo.accountNumber ? accountInfo.accountNumber : 'б/н')

    return (
      <div className={ classes.root }>
        <Card className={ classes.card }>
          <CardHeader disableTypography={ true }
               title= {
                 <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
                   Лицевой счет <hr/>
                 </Typography>}
          />
          <CardContent>
            <Grid container spacing={16} >
              <Grid item xs={12} sm={6} md={6} >
                <div style={{textAlign:"center"}}>
                  Номер:
                  <Typography gutterBottom variant="h6"> { accountNumber } </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6 } md={6} >
                <div style={{textAlign:"center"}}>
                  Баланс:
                  <Typography gutterBottom variant="h6" > {accountBalance} руб. </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>


        <Card className={ classes.card }>

            <CardHeader disableTypography={ true }
              title= {
                <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
                  Ваши подписки <hr/>
                </Typography>}
            />

            <CardContent>

              {
                this.props.userSubscription && this.props.userSubscription.length > 0 ?
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Тарифный план</TableCell>
                          <TableCell align="left">Описание</TableCell>
                          <TableCell align="center">Статус</TableCell>
                          <TableCell align="center">Стоимость</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.userSubscription.map(item => (
                          <TableRow key={item.id}>
                            <TableCell align="center">{item.serviceSpec.name}</TableCell>
                            <TableCell align="justify">{item.serviceSpec.comment}</TableCell>
                            <TableCell align="center" >
                              <Typography  style = {{color: colorByState(item.serviceState.id), fontWeight:"bold"}} >
                                  {item.serviceState.text}
                              </Typography>
                              </TableCell>
                            <TableCell align="center">{item.serviceSpec.price/100} руб.</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>

                    </Table>
                  </div>
                  :
                  <div>
                        <Typography variant="body1" gutterBottom align="left" className={ classes.btnAdd }>
                          Нет активных подписок
                        </Typography>
                  </div>
              }


            <Dialog
              aria-labelledby="max-width-dialog-title"
              open={ this.props.isShowWindowSelect }
              onClose={() => this.handleBtnCancel()}
              maxWidth={"lg"}
            >
              <DialogTitle style={{textAlign: 'center'}} disableTypography={true}>
                <Typography variant={'h6'} >Выберите тариф<hr/></Typography>
              </DialogTitle>
              <DialogContent>
                <SelectSubscription handleBtnCancel={ () => this.handleBtnCancel() }
                  selectedSubscrptionId={ this.state.selectedSubscrptionId }
                />
              </DialogContent>
            </Dialog>

{/*
            <Modal open={ this.props.isShowInvoice }>
              <Invoice redirectUrl='/subscription'/>
            </Modal>

            {
              this.props.isShowInvoicePdf && console.log("open Pdf win")
            }

            {
              this.props.isShowInvoicePdf && this.getSubscriptionPrice() &&
                <PdfWindow path="invoice" title="Платежное поручение"
                           params={{ amount: this.getSubscriptionPrice()}}
                           redirectUrlAfterClose="/subscription" />
            }
          */}
          </CardContent>
          <CardActions className={this.props.classes.cardActions}>

              <Button size="large" variant="contained" color="primary" className={ classes.btnAdd }
                      onClick={ () => this.handleAddSubscription() }>
                Выбрать тариф
              </Button>


          </CardActions>
        </Card>
      </div>
    );
  }
}

/*
const SubscriptionList = (props) =>
  props.userSubscription.map((item, index) => (
    <Grid item xs={12} key={`subs-item-${index}`}>
      <Paper className={ props.classes.oneSubs }>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography variant="h6" gutterBottom align="left" className={ props.classes.leftMargin } >
              { item.serviceSpec.name }
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" gutterBottom align="left">
              { item.serviceSpec.comment }
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" gutterBottom align="right" >
              <font color={ colorByState(item.serviceState.id) }>
                { item.serviceState.text }
              </font>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" gutterBottom align="right" style={{ marginRight: 10 }}>
              { `${item.serviceSpec.price / 100} руб.` }
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="body1" gutterBottom align="left" className={ props.classes.leftMargin }>
              Расчетный период
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" gutterBottom align="left">
              {`${ df(item.currentPeriodStartMoment) } - ${ df(item.currentPeriodStopMoment) }`}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ))

  const SubscriptionListMobile = (props) =>
    props.userSubscription.map((item, index) => (
      <Grid item xs={12} key={`subs-item-${index}`}>
        <Paper className={ props.classes.oneSubs }>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom align="left" className={ props.classes.leftMargin } >
                { item.serviceSpec.name }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom align="left" className={ props.classes.leftMargin }>
                { item.serviceSpec.comment }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom align="left" className={ props.classes.leftMargin }>
                <font color={ colorByState(item.serviceState.id) }>
                  { item.serviceState.text }
                </font>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom align="left" className={ props.classes.leftMargin }>
                { `${item.serviceSpec.price / 100} руб.` }
              </Typography>
            </Grid>
            <Grid item xs={12}/>

            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom align="left" className={ props.classes.leftMargin }>
                Расчетный период
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom align="left">
                {`${ df(item.currentPeriodStartMoment) } - ${ df(item.currentPeriodStopMoment) }`}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    ))
*/


function mapStateToProps(state) {
    const userSubscription = state.subscription && state.subscription.payload;
    const accountInfo = state.accountInfo && state.accountInfo.payload;

    return {
      userSubscription: userSubscription && userSubscription.data,
      isShowWindowSelect: userSubscription && userSubscription.isShowWindowSelect ? true : false,
      isShowInvoice: userSubscription && userSubscription.isShowInvoice ? true : false,
      isShowInvoicePdf: userSubscription && userSubscription.isShowInvoicePdf ? true : false,
      accountInfo: accountInfo && accountInfo.data
    };
}

const mapDispatchToProps = dispatch => ({
  fetchActiveSubscription: () => { dispatch({ type: types.FETCH_USER_SUBSCRIPTION }) },
  showWindowSelect: () => { dispatch({ type: types.SHOW_WINDOW_CHANGE_SUBSCRIPTION }) },
  closeWindowSelect: () => { dispatch({ type: types.CLOSE_WINDOW_CHANGE_SUBSCRIPTION }) },
  fetchAccountInfo: () => { dispatch({ type: types.FETCH_ACCOUNT_INFO }) },

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubscriptionView));
