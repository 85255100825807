import React, {PureComponent} from "react";
import {AppBar, Button, Link, Toolbar, Typography, withStyles,} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import redirect from '../lib/redirect';
import logo from 'images/logo_big.png';
import {connect} from 'react-redux';
import * as types from 'constants/action-types';
import AccountButton from 'components/account-button'

const drawerWidth = 240;

const styles = theme => ({
  appBar: {
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
    backgroundColor: 'black',
    minHeight: 80,
    maxHeight: 80,
    zIndex: theme.zIndex.drawer +1,
  },

  logo: {
    maxWidth: '90px',
    cursor: 'pointer'
  },
  logoDiv: {
    minWidth: drawerWidth,
    width: drawerWidth,
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  posBtn: {
    marginLeft: 'auto',
    marginRight: '40px',
    marginTop: '15px',
  },

  button: {
    textTransform: 'none',
    fontSize: 18,
    marginLeft: '40px'
  },
  btnReg: {
    marginRight: '10px',
  },
  btnLogin: {
    marginRight: '20px',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  userName: {
    color: 'white',
    verticalAlign: 'middle',
    fontSize: 18
  },

  iconButton: {
    marginTop: theme.spacing.unit *2,
    marginLeft: theme.spacing.unit,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  subscrDiv: {
    marginLeft: theme.spacing.unit *8,
    minWidth: 240,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  balanceDiv: {
    marginLeft: theme.spacing.unit *8,
    minWidth: 240,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },


})

const LogoutBtn = props => {
//  const { handleBtnLogout } = props;
  return (
    <React.Fragment>
      <AccountButton user={ props.user } handleBtnLogout={ props.handleBtnLogout } />
    </React.Fragment>
  )
}

const LoginBtn = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <Button onClick={ () => redirect('/register') } variant="text" color="secondary" className={ classes.button }>
        Регистрация
      </Button>
      <Button onClick={ () => redirect('/login') } variant="text" color="secondary" className={ classes.button }>
        Войти
      </Button>
    </React.Fragment>
  )
}

class IdxAppBar extends PureComponent {


  componentDidUpdate() {
//    this.props.getStat();
  }


  handleClickLogo = () => {
    redirect("/");
  }

  handleDrawerToggle = () => {
    const newStateOpen = this.props.isDrawerOpen ? false : true;
    this.props.handleDrawerToggle({ open: newStateOpen });
  }

  handleClick(e, path)  {
    e.preventDefault();
    redirect(path);
  }

  // getActualSubscription() {
  //   if (this.props.userSubscription) {
  //     const active = this.props.userSubscription.filter(item => item.serviceState.active === true);
  //     if (active && active.length >= 1) {
  //       return active[0].serviceSpec.name.toLowerCase()
  //     }
  //   }
  //   return "";
  // }

  render() {
    const { classes} = this.props;

    return (
      <AppBar position="fixed" className={ classes.appBar }>
        <Toolbar  disableGutters={true}  >
          <IconButton color="inherit" aria-label="open drawer" edge="start" className={ classes.iconButton }
                      onClick={ () => this.handleDrawerToggle() } >
            <MenuIcon />
          </IconButton>

          <div className={classes.logoDiv}>
          <img alt="IDX logo" src={ logo } className={ classes.logo } onClick={ () => this.handleClickLogo() }/>
          </div>

          <div className={classes.subscrDiv}>
                  <Typography variant="subtitle1" color="inherit">
                    Тарифный план:
                    <Link style={{ marginLeft: 10 , color:"white", textDecoration: 'underline'}} href="#" onClick={(e)=>this.handleClick(e,'/subscription')} >
                      { this.props.activeServiceSpecName}
                    </Link>
                  </Typography>
                  <Typography variant="subtitle1" color="inherit">
                    Осталось проверок:
                    <span style={{  marginLeft: 10, color: '#39df8d' }}>
                    { this.props.leftCnt }
                    </span>
                  </Typography>
          </div>
          <div className={classes.balanceDiv}>
                  <Typography variant="subtitle1" color="inherit">
                    Баланс
                  </Typography>
                  <Typography variant="subtitle1" color="secondary">
                    { this.props.accountBalance } руб.

                      <Link style={{ marginLeft: 10 , color:"white", textDecoration: 'underline'}} href="#" onClick={(e) => this.handleClick(e,'/payment')} >
                        <span style={{fontSize: "small"}}>Пополнить</span>
                      </Link>


                  </Typography>


          </div>


          <div className={ classes.posBtn }>
            { this.props.party && this.props.party.id ?
              <LogoutBtn classes={ classes } handleBtnLogout={ this.props.handleBtnLogout } user={ this.props.party.username }/>
              :
              <LoginBtn classes={ classes }/>
            }
          </div>
        </Toolbar>

      </AppBar>
    )
  }
}

function mapStateToProps(state) {
  const party = state.party && state.party.payload && state.party.payload.data;
  const drawer = state.mobileDrawer && state.mobileDrawer.payload && state.mobileDrawer.payload;
  const activeServiceSpecName  = state.getStat && state.getStat.payload && state.getStat.payload.data && state.getStat.payload.data.activeServiceSpecName? state.getStat.payload.data.activeServiceSpecName: 'Вы не подписаны';

  const accountBalance  = state.getStat && state.getStat.payload && state.getStat.payload.data? state.getStat.payload.data.accountBalance/100 : 0;
  const leftCnt = state.getStat && state.getStat.payload && state.getStat.payload.data ? state.getStat.payload.data.leftCnt: 0;
  //console.log("activeServiceSpecName=["+activeServiceSpecName+"]");
  return {
    party,
    isDrawerOpen: drawer && drawer.open ? true : false,
    activeServiceSpecName: activeServiceSpecName,
    accountBalance: accountBalance,
    leftCnt: leftCnt

  };
}

const mapDispatchToProps = dispatch => ({
  handleBtnLogout: () => { dispatch({ type: types.LOGOUT }) },
  handleDrawerToggle: (payload) => { dispatch({ type: types.MOBILE_DRAWER, payload }) },
//  getStat: (payload) => { dispatch({ type: types.GET_STAT, payload }) },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IdxAppBar));
