import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Dadata from 'dadata-suggestions';
import daDataFormatter from '../../lib/da-data-formatter';

const dadata = new Dadata(process.env.REACT_APP_DADATA_KEY);

const renderInput = (inputProps)=> {
  const { classes, refs, ...other } = inputProps;

  return (
    <TextField
      name="auto-complete"
      fullWidth
      margin="dense"
      label="Поиск по наименованию, ИНН или ОГРН"
      variant="outlined"
      placeholder= 'Введите минимум 4 символа'
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
      }}
      {...other}
    />
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    p_height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: 0,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit *2,
  },
  innLabel1: {
    margin: 0,
    fontSize: 13,
  },
  innLabel2: {
    marginTop: 0,
    fontSize: 11
  }
});

class AutocompleteDownshift extends PureComponent {
  state = { suggestions: [] }

  getSuggestions = event => {
    const self = this;
    const val = event.target.value;

    if (val && val.length > 3) {
      dadata.party({ query: val, count: 9 })
        .then((data) => {
          const r = daDataFormatter(data);

          self.setState({ suggestions: r });
        })
    } else {
      self.setState({ suggestions: [] });
    }

    return true;
  }

  renderSuggestion({ highlightedIndex, getItemProps, classes, selectedItem }) {
    return (
      this.state.suggestions.map((suggestion, index) =>
        <MenuItem
            {...getItemProps({ item: suggestion })}
            key={ suggestion.key }
            value={ index }
            selected={ highlightedIndex === index }
            style={{
              fontWeight: ((selectedItem ? selectedItem.key : '').indexOf(suggestion.key) >= 0) ? 500 : 400,
            }}
            component="div"
        >
          <div>
            <div className={classes.innLabel1}> { suggestion.value } </div>
            <div className={classes.innLabel2}> { suggestion.inn } </div>
          </div>
        </MenuItem>
      )
     );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.root }>
        <Downshift id="autoSelect"
          onChange={ selection =>  this.props.onSelect(selection) }
          itemToString={ item => ('') }
          itemToStringOld={ item => (item ? item.inn : '') }
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => (
            <div className={ classes.container }>
              { renderInput({
                fullWidth: true,
                classes,
                refs: this.props.refs,
                InputProps: getInputProps({
                  onChange: this.getSuggestions,
                })
              })}
              <div { ...getMenuProps() }>
                { isOpen ? (
                  <Paper className={ classes.paper } square>
                    { this.renderSuggestion({
                        highlightedIndex,
                        getItemProps,
                        classes,
                        selectedItem
                    })}
                   </Paper>
                ) : null }
              </div>
            </div>
          )}
        </Downshift>
      </div>
    )
  }
}

AutocompleteDownshift.propTypes = {
  classes: PropTypes.object.isRequired,
  //refs: PropTypes.array.isRequired,
};


export default withStyles(styles)(AutocompleteDownshift);
