import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* fetchBillOperation(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const operation = yield call(() => axios.post('/bill-operation', action.payload));
      yield put({ type: types.FETCH_BILL_OPERATION_SUCCEEDED, payload: operation });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
function* watchFetchBillOperation() {
  yield takeEvery(types.FETCH_BILL_OPERATION, fetchBillOperation);
}

export { watchFetchBillOperation };
