import React, {PureComponent} from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Toolbar from '@material-ui/core/Toolbar';
import * as types from '../constants/action-types';
import {connect} from 'react-redux';
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";

const styles = theme => ({
  wrapperDiv: {
    marginLeft: 10,
    marginRight: 10,
  },
  cardContent: {
    padding: 30,
  },
  cardActions: {
    padding: 30,
  },

  downloadBtn: {
    //    background:
  }
});

class Offer extends PureComponent {

  componentDidMount() {
    this.props.loadOffer({type:"html"});
  }


  render() {
    return (
      <div className={ this.props.classes.wrapperDiv }>
        <Card className={ this.props.classes.card }>
          <Toolbar className={ this.props.classes.toolbar }>
          </Toolbar>
          <CardContent className={ this.props.classes.cardContent }>
            <div dangerouslySetInnerHTML= { {__html: this.props.offerHtml} } />
          </CardContent>
          <CardActions className={ this.props.classes.cardActions }>
            <Button  variant="contained" size="medium" color="primary" className={ this.props.classes.downloadBtn } onClick={() => this.props.loadOffer({type:"pdf"})} >
              Скачать договор в PDF
            </Button>
          </CardActions>
        </Card>
      </div>

    )
  }

}

function mapStateToProps(state) {
//  console.log("offer", state.loadOffer && state.loadOffer.payload && state.loadOffer.payload.data? state.loadOffer.payload.data: "")

  return {
    offerHtml: state.loadOffer && state.loadOffer.payload && state.loadOffer.payload.data? state.loadOffer.payload.data: "",
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loadOffer: (payload) => dispatch({type: types.LOAD_OFFER, payload}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(Offer));
