import {push} from 'connected-react-router';
import log from './logger';
import store from 'store';

const redirect = (page, params) => {
  //log(`push history: ${page}`)
  console.log("push history:", page);
  store.dispatch(push(page, { 'params': params }))
}

export default redirect;
