import axios from 'axios';
import {JWT_STORAGE} from 'constants/app-global'

const getBasePath = () => {
  if (process.env.REACT_APP_BASE_URL === '/') {
    return window.location.origin;
  } else {
    return process.env.REACT_APP_BASE_URL;
  }
}

// Create instance
let instance = axios.create({
  baseURL: `${getBasePath()}/${process.env.REACT_APP_VERSION}/`
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem(JWT_STORAGE);
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

export default instance;
export { getBasePath } ;
