import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* addVerifyToken(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const response = yield call(() => axios.post('/verifyLogin', action.payload));
    yield put({ type: types.ADD_VERIFY_TOKEN_SUCCEEDED, payload: response });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
export function* watchAddVerifyToken() {
  yield takeEvery(types.ADD_VERIFY_TOKEN, addVerifyToken);
}

