import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* fetchAccountInfo(action) {
  try {
    yield put({ type: types.LOADING_TRUE });
    const accountInfo = yield call(() => axios.post('/account-info', action.payload));
    yield put({ type: types.FETCH_ACCOUNT_INFO_SUCCEEDED, payload: accountInfo });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
function* watchFetchAccountInfo() {
  yield takeEvery(types.FETCH_ACCOUNT_INFO, fetchAccountInfo);
}

export { watchFetchAccountInfo };
