import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {relative} from 'path';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import redirect from 'lib/redirect';
import {EMAIL_SEND_RESET_PWD} from 'constants/action-types';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {maxWidth: 600,
    marginBottom: 20,
    margin: '0 auto',
    textAlign:'center'
  },
  cardHeader: {padding:30},
  cardActions: {padding:30},
  btnMain: { marginBottom: 20 },

  rememberBtn: {
    textTransform: 'none',
    fontSize: "12px"
},

});

/**
 * Форма сброса пароля
 */
class ResetPassword extends PureComponent {
  resetFields = (step) => {
    switch (step) {
      case 0:
        return <EnterEmail { ...this.props } handleEmailSendReset={ this.props.handleEmailSendReset } />
      case 1:
        return <EnterEmailResponse { ...this.props } />
      default:
        return <React.Fragment/>
    }
  }




  render() {
    const { classes, resetPwd } = this.props;
    return (
      <div className={ classes.root }>
        <Card className={ classes.card } >
          <CardHeader className={classes.cardHeader}
                      title={<Typography variant={'h5'} >Сброс пароля<hr/></Typography>}
          />
          <CardContent>
            <Grid  container spacing={16} direction="row" justify="flex-end"  alignItems="center" >
              {/* <EnterEmail { ...this.props } handleEmailSendReset={ handleEmailSendReset } />*/}
              { this.resetFields(resetPwd.step) }
            </Grid>
          </CardContent>
{/*          <CardActions className={classes.cardActions}>
          </CardActions>*/}
        </Card>
      </div>
    );
  }
}

class EnterEmail extends PureComponent {
  state = { email: '' }

  btnEmailResetPwd() {
    this.props.handleEmailSendReset(this.state);
  }
  handleRememberButton = () => {
    redirect('/login');
  }
  render() {
    const { classes } = this.props;
    return(
      <React.Fragment>
        <Grid item xs={2}>
          <Typography variant="body1" gutterBottom align="right">Email</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField name="email" style={{ width: '99%' }}
            /*margin="dense"*/
            variant="outlined" placeholder="Электронная почта"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value = { this.state.email }
            onChange={ (e) => this.setState({ [e.target.name]: e.target.value}) }
          />
        </Grid>
        <Grid item xs={1}/>

        <Grid item xs={12} style={{textAlign: "center" }}>
          <Button variant="contained" color="primary" className={ classes.ctrMargin } onClick={() => this.btnEmailResetPwd() } >
            Сбросить пароль
            {/*<ForwardIcon className={ classes.rightIcon }/>*/}
          </Button>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <Button className={classes.rememberBtn} onClick={this.handleRememberButton}>
            <span style={{borderBottom: '1px solid #1e2f45'}}> я вспомнил пароль </span>
          </Button>
        </Grid>


      </React.Fragment>
    );
  }
}

const EnterEmailResponse = (props) => (
  <React.Fragment>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom align="center">{ props.resetPwd.data }</Typography>
    </Grid>
  </React.Fragment>
)

function mapStateToProps(state) {
  const resetPwd = state.resetPwd && state.resetPwd.payload;

  return {
    resetPwd,
  };
}

const mapDispatchToProps = dispatch => ({
  handleEmailSendReset: (payload) => dispatch({ type: EMAIL_SEND_RESET_PWD, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPassword));
