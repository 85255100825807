import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import TextField from '@material-ui/core/TextField';
import {FormControlLabel, Link, Radio, RadioGroup} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/Forward';
import logo from 'images/logo1.jpg';
import {PARTY_UPDATE, REGISTR_STEP1} from 'constants/action-types';
import redirect from '../../lib/redirect';
import InputMask from 'react-input-mask';

const red500 = red['500'];

const errorClassName = {
  fontSize: '12px',
  color: red500,
  position: 'absolute',
  marginTop: '-7px',
};

const styles = theme => ({
  ctrMargin: {
    margin: theme.spacing.unit,
    transformText: false
  },
  // leftIcon: {
  //   marginRight: theme.spacing.unit,
  // },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  posCentered: {
    textAlign: 'center'
  },
  haveLoginBtn: {
    textTransform: 'none',
    fontSize: "12px"
  },
  grid: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '60px', paddingRight: '60px'
    },

  }
});

class FieldsStep1 extends PureComponent {
  state = {
    initialSet: false,
    isFormValid: false
  }

  propsExt = {
    ...this.props,
    refs: {},
    classes:  styles,
    validation: {},
    isLegal:null
  }

  componentDidMount() {
    const self = this;
    const refs = this.propsExt.refs;
    console.log(refs);

    if (this.props.openType === "edit" && this.props.party && !this.state.initialSet) {
      const { party } = this.props;

      Object.keys(refs).forEach(function (key) {
        refs[key].value = party[key] ? party[key] : null
      })
      this.setState({ initialSet: true });
    }


    Object.keys(refs).forEach(function (key) {
//      console.log(key);
//      console.log(refs[key]);
      if (refs[key].required) {
        const isValid = self.checkValid(key, refs[key].value)
        self.propsExt.validation[key] = isValid ? "" : "Требуется обязательное заполнение";
      }
    });
  }

  handleChange = (event) => {
    const v = this.propsExt.validation;
    const fieldName = event.target.name;

    const isValid = this.checkValid(fieldName, event.target.value);
    v[fieldName] = isValid ? "" : "Требуется обязательное заполнение";

    this.checkFormValid();
    this.forceUpdate();
  }

  checkFormValid() {
    const self = this;
    let notValid = false;
    const refs = this.propsExt.refs;

    Object.keys(refs).forEach(function (key) {
//      console.log(key+".required="+refs[key].required);

      if ((refs[key].required || (refs[key].props && refs[key].props.required)) && !self.checkValid(key, refs[key].value)) {
        notValid = true;
      }
    });

//    console.log("notValid="+notValid+", isLegal="+this.propsExt.isLegal);
    this.setState({ isFormValid: (!notValid && this.propsExt.isLegal!= null) });
  }

  checkValid(fieldName, value) {
//    console.log(fieldName+"="+value);
    let v = value.trim();
    if (v.length === 0) {
      return false;
    }

    if (fieldName === "email") {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(v).toLowerCase());
    }

    if (fieldName === "phone") {
      let re = /^\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/;
      //console.log("phone checkValid=", re.test(String(v).toLowerCase()));
      return re.test(String(v).toLowerCase());
    }

    return true;
  }

  isLegalChanged = (event) => {
    //console.log("------------");
    this.propsExt.isLegal = event.target.value;
    this.checkFormValid();
  }

  myClick = (e) => {
    const postData = {};
    const refs = this.propsExt.refs;

    Object.keys(refs).forEach(function (key) {
      postData[key] = refs[key].value;
    })
    if (this.propsExt.openType === "edit") {
      this.props.handleUpdateButton(postData);

    } else {
      postData["isLegal"] = this.propsExt.isLegal;
      this.props.handleRegistrButton(postData);
    }
  }

  handleHaveLoginButton =() =>{
    redirect("/login");
  }

  render() {
    const { classes, openType } = this.props;
    this.propsExt.onChange = this.handleChange;
    this.propsExt.isLegalChanged = this.isLegalChanged
    this.propsExt.myClick = this.myClick

//    console.log("register step1");
    return(
      <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center" className={classes.grid} >

        { openType !== 'edit'  ?

          <React.Fragment>
            <Grid item xs={12} className={ classes.posCentered }>
              <img src={logo} width="150" alt={""}/>
            </Grid>

            <Grid item xs={12}  className={ classes.posCentered }>
              <Typography gutterBottom variant="h4" style={{marginLeft: 15}}>
                  РЕГИСТРАЦИЯ
              </Typography>
              <Typography gutterBottom variant="h5" style={{marginLeft: 15}}>
                демо-доступ на 24 часа
              </Typography>
            </Grid>
          </React.Fragment>
          :
          <Grid item xs={12}  className={ classes.posCentered }>
            <Typography gutterBottom variant="h5" style={{marginLeft: 15}}>
              Изменить учетную запись <hr/>
            </Typography>
          </Grid>
        }

        <Email { ...this.propsExt } />
        {
          this.props.openType !== "edit" && <Password { ...this.propsExt } />
        }
        {
          this.props.openType !== "edit" && <FIO { ...this.propsExt } />
        }
        {
          this.props.openType !== "edit" && <Phone { ...this.propsExt } />
        }
        {
          this.props.openType !== "edit" && <IsLegal { ...this.propsExt } />
        }




        <BtnContinue { ...this.propsExt } isFormError={ !this.state.isFormValid } />

        {
        this.props.openType !== "edit" &&
        <Grid item xs={12} style={{textAlign: "center"}}>
          <Button className={classes.haveLoginBtn} onClick={this.handleHaveLoginButton}>
            <span style={{borderBottom: '1px solid #1e2f45'}}> У меня есть логин </span>
          </Button>
        </Grid>
        }

        {
          this.props.openType !== "edit" &&
            <Typography gutterBottom  style={{margin: 50}} align="center">
              Продолжая пользоваться сервисом, Вы соглашаетесь с условиями
              <Link href="//iidx.ru/IDX_oferta.pdf" target="_blank"> <strong>договора </strong></Link>
            </Typography>
        }

      </Grid>
    )
  }
}

const Email = (props) =>
  <React.Fragment>
    <Grid item xs={12} >
      <React.Fragment>
        <TextField name="email" alabel="Email" label="Email" fullWidth
          //style={{ minWidth: '100%' }}
          //className={ props.classes.fields }
          margin="dense" variant="outlined"
          placeholder="Email"
          disabled={ props.openType === 'edit' }
          required={ true }
          onChange={ e => { props.onChange(e) }}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <AccountCircle />
          //     </InputAdornment>
          //   ),
          // }}
          inputRef={ el => {
            if (el && el.name) {
              props.refs[el.name] = el
            }
          }}
        />
        {
          props.validation["email"] &&
            <div style={ errorClassName }> { props.validation["email"] } </div>
        }
      </React.Fragment>
    </Grid>
  </React.Fragment>

class Password extends PureComponent {
  state = {
    showPassword: false,
    validationText: "Требуется обязательное заполнение"
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  componentWillReceiveProps(prop) {
    this.setState({ validationText: prop.validation['passwordNew']});
  }


  render() {
    return(
      <React.Fragment>
        <Grid item xs={12} >
          <React.Fragment>
            <TextField
              name="passwordNew"
              type={ this.state.showPassword ? 'text' : 'password' }
              fullWidth
              margin="dense"
              variant="outlined"
              placeholder="Пароль" label="Пароль"
              required={ true }
              onChange={ e => { this.props.onChange(e) }}
              inputRef={ el => {
                if (el && el.name) { this.props.refs[el.name] = el }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                    { this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {
              this.state.validationText &&
                <div style={ errorClassName }> { this.state.validationText }  </div>
            }
          </React.Fragment>
        </Grid>
      </React.Fragment>
    )
  }
}


const FIO = (props) =>
  <React.Fragment>
    <Grid item xs={12} >
      <TextField name="personName" fullWidth margin="dense" variant="outlined"
                 placeholder="Фамилия Имя Отчество" label="Контактное лицо"
                 required={ true }
                 onChange={ e => { props.onChange(e) }}
                 inputRef={ el => {
                   if (el && el.name) { props.refs[el.name] = el }
                 }}
      />
    </Grid>
  </React.Fragment>

const IsLegal = (props) =>
  <React.Fragment>
    <Grid item xs={12} style={{ paddingLeft: '60px'}}>
      <RadioGroup aria-label="Тип" name="isLegal" defaultValue={true} required={true}  onChange={props.isLegalChanged}>
        <FormControlLabel value="true" label="Юридическое лицо" control={<Radio  />} />
        <FormControlLabel value="false" label="Физическое лицо" control={<Radio />} />
      </RadioGroup>
    </Grid>
  </React.Fragment>


class Phone extends PureComponent {
  state = {
    value: this.props.party ? this.props.party.phone : '',
    validationText: "Требуется обязательное заполнение"
  }

  onChange1 = (event) => {
    this.setState({ value: event.target.value });
    this.props.onChange(event);
  }

  componentWillReceiveProps(prop) {
    this.setState({ validationText: prop.validation['phone']});
  }
  render() {
    return(
      <React.Fragment>
        <Grid item xs={12} >
          <InputMask mask="(999) 999-99-99" maskChar={ null } value={ this.state.value }  onChange={ this.onChange1 } >
            {(inputProps) =>
              <TextField name="phone" fullWidth margin="dense" variant="outlined" placeholder="(999) 999-99-99" label="Контактный телефон"
                         required={ true }
                         inputRef={ el => {
                           if (el && el.name) { this.props.refs[el.name] = el }
                         }}
              />
          }
          </InputMask>
        </Grid>
      </React.Fragment>
    );
  }
}




const BtnContinue = (props) => {
  const { classes} = props;
  return (
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Button variant="contained" color="primary" className={ classes.ctrMargin } onClick={ props.myClick } disabled={ props.isFormError }>
        { props.openType === 'edit' ? "Изменить" : "Продолжить" }
        {/*<ForwardIcon className={classes.rightIcon}/>*/}
      </Button>
    </Grid>
  )
}

FieldsStep1.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const party = state.party && state.party.payload && state.party.payload.data;

  return {
    party
  };
}

const mapDispatchToProps = dispatch => ({
  handleRegistrButton: (payload) => { dispatch({ type: REGISTR_STEP1, payload }) },
  handleUpdateButton: (payload) => { dispatch({ type: PARTY_UPDATE, payload }) }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FieldsStep1));
