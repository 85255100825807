import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import * as types from '../constants/action-types';
import formatErrorMsg from 'lib/format-error.js';
import {saveAs} from 'file-saver';
import qs from 'qs';


function* invoiceDownload(action) {

  try {
    yield put({ type: types.LOADING_TRUE });
    const response = yield call(() => axios.post('/invoiceDownload', qs.stringify(action.payload), {  responseType: 'blob'}));
    var d = new Date();

    const date = ('0'+d.getDate()).substr(-2) + '-' + ('0'+(d.getMonth()+1) ).substr(-2) + '-' + d.getFullYear();
    saveAs(response.data, "Счет на оплату услуг IDX от "+date+".pdf");
    return;
  }
  catch (e) {
    const payload = { snakeVariant: 'error',snakeMessage: formatErrorMsg(e) }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}


// Sagas
function* watchInvoiceDownload() {
  yield takeEvery(types.INVOICE_DOWNLOAD, invoiceDownload);
}

export {watchInvoiceDownload};
