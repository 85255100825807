import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from '../lib/format-error';
import redirect from 'lib/redirect';
import * as types from '../constants/action-types'
import {JWT_STORAGE} from 'constants/app-global'

function* loginUser(action) {
  try {
//    yield put({ type: types.LOADING_TRUE });

    if (action.payload) {
      const token = yield call(() => axios.post('/login', action.payload));
      localStorage.setItem(JWT_STORAGE, token.data);
    }

    //получение данных учетной записи юзера, по токену
    if (localStorage.getItem(JWT_STORAGE)) {
      const user = yield call(() => axios.post('/party/viewOld'));
      yield put({ type: types.LOGIN_SUCCEEDED, payload: user });
      //redirect("/");
    }
    else {
      redirect("/login");
    }
  }
  catch (e) {
    localStorage.removeItem(JWT_STORAGE);
    yield put({ type: types.FETCH_PARTY_ERROR });
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
  //  yield put({ type: types.LOADING_FALSE });
  }
}

//Без прогресса
function* fetchParty(action) {
  try {
//    console.log("fetchparty.action.payload", action.payload);
    const user = yield call(() => axios.post('/party/viewOld'));
    const payload = Object.assign({}, user, action.payload);
    yield put({ type: types.FETCH_PARTY_SUCCEEDED, payload });
  }
  catch (e) {
    localStorage.removeItem(JWT_STORAGE);
    yield put({ type: types.FETCH_PARTY_ERROR });
    yield put({ type: types.SNAKE_OPEN, payload:{ snakeVariant: 'error', snakeMessage: formatErrorMsg(e) } });
  }
}

function* generateKeys(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const user = yield call(() => axios.post('/genkeys'));
    yield put({ type: types.FETCH_PARTY_SUCCEEDED, payload: user });
  }
  catch (e) {
    localStorage.removeItem(JWT_STORAGE);
    yield put({ type: types.FETCH_PARTY_ERROR });

    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* emailSendResetPwd(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const response = yield call(() => axios.post('/email-reset-pwd', action.payload));
    yield put({ type: types.EMAIL_RESETPWD_SUCCEEDED, payload: response });
  }
  catch (e) {
    localStorage.removeItem(JWT_STORAGE);
    yield put({ type: types.FETCH_PARTY_ERROR });

    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

function* setNewPassword(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const response = yield call(() => axios.post('/set-pwd', action.payload));
    yield put({ type: types.NEW_PWD_SUCCEEDED, payload: response });
  }
  catch (e) {
    //localStorage.removeItem(JWT_STORAGE);
    yield put({ type: types.NEW_PWD_FAIL });

    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}



function* resendEmailConfirm(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const response = yield call(() => axios.post('/resend-email-confirm', action.payload));
    yield put({ type: types.EMAIL_RESEND_CONFIRM_SUCCEDED, payload: response });

    const payload = {
      snakeVariant: 'info',
      snakeMessage: { text: response.data, statusCode: 200 }
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  catch (e) {
    const payload = {
      snakeVariant: 'error',
      snakeMessage: formatErrorMsg(e)
    }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}


function* changePassword(action) {
  try {
    yield put({ type: types.LOADING_TRUE });
    yield call(() => axios.post('/changePassword', action.payload));
    yield put({ type: types.PARTY_CHANGE_PASSWORD_DONE});
    yield put({ type: types.SNAKE_OPEN, payload: { snakeVariant: "info", snakeMessage:{text:"Пароль успешно изменен"}} });
  }
  catch (e) {
    const payload = { snakeVariant: 'error', snakeMessage: formatErrorMsg(e) }
    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}


// Sagas
export function* watchChangePassword() {
  yield takeEvery(types.PARTY_CHANGE_PASSWORD, changePassword);
}



function* watchFetchUser() {
  yield takeEvery(types.LOGIN, loginUser);
}

function* watchFetchParty() {
  yield takeEvery(types.FETCH_PARTY, fetchParty);
}

function* watchGenerateKeys() {
  yield takeEvery(types.GEN_API_KEYS, generateKeys);
}

function* watchEmailResetPwd() {
  yield takeEvery(types.EMAIL_SEND_RESET_PWD, emailSendResetPwd);
}

function* watchSetNewPassword() {
  yield takeEvery(types.SET_NEW_PWD, setNewPassword);
}

function* watchResendEmailConfirm() {
  yield takeEvery(types.EMAIL_RESEND_CONFIRM, resendEmailConfirm);
}


export { watchFetchUser, watchFetchParty, watchGenerateKeys,
         watchEmailResetPwd, watchSetNewPassword, watchResendEmailConfirm };
