import {MOBILE_DRAWER} from 'constants/action-types';

const initialState = {
  payload: null,
  error: null
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MOBILE_DRAWER:
      return { ...state, error: false, payload }
    default:
      return state;
  }
};
