import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {relative} from 'path';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import * as types from '../../constants/action-types';
import {connect} from "react-redux";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit *2,
    paddingBottom: theme.spacing.unit *2,
    background: '#eeeeee',
    position: relative
  },
  card: {
    maxWidth: 720,
    marginBottom: 20,
    margin: '0 auto',
    //        minHeight: '634px'
  },
  cardActions: {
    padding:theme.spacing.unit *4,
    //textAlign: 'center',
    justifyContent: 'center',
  }
});

/**
 * Форма изменения Аккаунта
 * здесь меняем только пароль, вводя старый, а потом 2 раза новый
 */
class AccountEditForm extends PureComponent {
  state = {password1: null, password2: null, showPassword1: false, showPassword2: false , password0:null, showPassword0:false};
  //state = {pass:[null,null,null], showPass:[false,false,false]};

  handleClickShowPassword(val) {
    //console.log(val);
    if (val === 0)
      this.setState(state => ({ showPassword0: !state.showPassword0 }));
    else if (val === 1)
      this.setState(state => ({ showPassword1: !state.showPassword1 }));
    else if (val === 2)
      this.setState(state => ({ showPassword2: !state.showPassword2 }));
  }

  handleSaveBtn(e) {
    e.preventDefault();
    //console.log("save");
    this.props.handleUpdateButton({
      password0: this.state.password0,
      password1:this.state.password1,
      password2: this.state.password2,
    });

  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.root }>
        <Card className={ classes.card }>
          <CardHeader   disableTypography={true} title={
            <Typography gutterBottom variant="h5" style={{textAlign: 'center'}}>
              Учетная запись<hr/>
            </Typography>
          }/>

          <CardContent>

            <Grid container spacing={16} direction="row" justify="flex-end" alignItems="center" >

              <Grid item xs={3}>
                <Typography variant="body1" gutterBottom align="right">Логин (Email)</Typography>
              </Grid>
              <Grid item xs={9} >
                  <TextField name="email" style={{ minWidth: '95%' }} margin="dense" variant="outlined" placeholder="Email" disabled={ true } defaultValue={this.props.party.email}
                             InputProps={{
                               startAdornment: (
                                 <InputAdornment position="start">
                                   <AccountCircle />
                                 </InputAdornment>
                               ),
                             }}
                  />
              </Grid>

              <Grid item xs={3}/>
              <Grid item xs={9}> <hr  /> </Grid>

              <Grid item xs={3}>
                <Typography variant="body1" gutterBottom align="right">Старый пароль</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  name="password0" type={ this.state.showPassword0 ? 'text' : 'password' }
                  fullWidth margin="dense" style={{ width: '95%' }} variant="outlined" placeholder="Пароль"
                  onChange={ this.handleChange("password0") }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => this.handleClickShowPassword(0) }>
                          { this.state.showPassword0 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body1" gutterBottom align="right">Новый пароль</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField name="password1" type={ this.state.showPassword1  ? 'text' : 'password' }
                  fullWidth margin="dense" style={{ width: '95%' }} variant="outlined" placeholder="Пароль"
                  onChange={ this.handleChange("password1") }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={ () => this.handleClickShowPassword(1) }>
                          { this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>


              <Grid item xs={3}>
                <Typography variant="body1" gutterBottom align="right">Повторите пароль</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField name="password2" type={ this.state.showPassword2 ? 'text' : 'password' }
                           fullWidth margin="dense" style={{ width: '95%' }}
                           variant="outlined" placeholder="Пароль"
                           onChange={ this.handleChange("password2") }
                           InputProps={{
                             endAdornment: (
                               <InputAdornment position="end">
                                 <IconButton onClick={ () =>  this.handleClickShowPassword(2) } >
                                   { this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                 </IconButton>
                               </InputAdornment>
                             )
                           }}
                />
              </Grid>

            </Grid>
          </CardContent>
          <CardActions className={this.props.classes.cardActions}>
            <Button size="large" variant="contained" color="primary" onClick={ (e) => this.handleSaveBtn(e) }
                    disabled={ (this.state.password0 && this.state.password1 && this.state.password2)?false:true} >
              Изменить пароль
            </Button>

          </CardActions>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const party = state.party && state.party.payload && state.party.payload.data;
  return {
    party
  };
}

const mapDispatchToProps = dispatch => ({
  handleUpdateButton: (payload) => { dispatch({ type: types.PARTY_CHANGE_PASSWORD, payload }) }
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountEditForm));
//export default withStyles(styles)(AccountEditForm);
