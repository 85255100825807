import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import redirect from 'lib/redirect';
import LoginSubForm from './login-form';
//import Registr from 'pages/party'
import ConfirmEmail from './confirm-email'
import ResetPassword from './reset-password'
import EnterNewPassword from './enterNewPassword'
import {REDIRECT_STEP2} from 'constants/action-types';

const styles = theme => ({

});


/**
 * Контейнер Логина или регистрации
 */
class LoginForm extends PureComponent {
  componentDidMount() {
    //console.log("RegisterForm did mount")
  // }
  // componentDidUpdate() {
//    console.log("LoginForm componentDidMount");
    const { setRegStep2, party} = this.props;

    if (party && party.data /*&& party.data.partyAttrId*/ ) {
      if (!party.data.isLegal) {
        /*физлица*/
        if (party.data.emailConfirmed) {
          redirect('/');
        }
        else {
          redirect('/confirmEmail');
        }
      }
      else {
        // юрлица
        if (party.data.partyAttrId) {
          if (party.data.emailConfirmed) {
            redirect('/');
          }
          else {
            redirect('/confirmEmail');
          }
        }
        else {
          setRegStep2();
        }
      }
    }

    else {
      redirect("/login");
    }
  }

  render() {

    return (
      <LoginSubForm/>
/*
      <React.Fragment>
        { party === null ? <LoginForm/>  :  <Registr /> }
      </React.Fragment>*/
    );
  }
}

function mapStateToProps(state) {
  const party = state.party && state.party.payload;
//  console.log("LoginForm.mapStateToProps, party:", party);
  return {
    party,
    registrStep: party ? party.nextStep : 0
  };
}

const mapDispatchToProps = dispatch => ({
    setRegStep2: () => dispatch({ type: REDIRECT_STEP2 })
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginForm));
export { ConfirmEmail, ResetPassword, EnterNewPassword } ;
