import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* profilePersonUpdate(data) {
  try {
    yield put({ type: types.LOADING_TRUE });
    yield call(() => axios.post('/party/update', data.payload));
    yield put({ type: types.SNAKE_OPEN, payload: {snakeVariant: 'info', snakeMessage: { text: "Данные успешно изменены"}}});
  }
  catch (e) {
    yield put({ type: types.SNAKE_OPEN, payload: {snakeVariant: 'error', snakeMessage: formatErrorMsg(e)}});
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

export function* watchProfilePersonUpdate() {
  yield takeEvery(types.PROFILE_PERSON_UPDATE, profilePersonUpdate);
}


