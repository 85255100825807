import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'lib/axios';
import formatErrorMsg from 'lib/format-error';
import * as types from 'constants/action-types'

function* getDocPdf(action) {
  try {
    yield put({ type: types.LOADING_TRUE });

    const report = yield call(() => axios(action.payload.url, {
      method: 'GET',
      responseType: 'blob',
      params: action.payload.params
    }));

    yield put({ type: types.COMPLETE_DOC_PDF, payload: report });
  }
  catch (e) {
    let errMsg = {};

    if (e.response && e.response.data && e.response.data instanceof Blob) {
      errMsg = formatErrorMsg("Ошибка обработки запроса");
    }
    else {
      errMsg = formatErrorMsg(e);
    }

    const payload = {
      snakeVariant: 'error',
      snakeMessage: errMsg
    }

    //console.log('loa', payload)

    yield put({ type: types.SNAKE_OPEN, payload });
  }
  finally {
    yield put({ type: types.LOADING_FALSE });
  }
}

// Sagas
function* watchRequestPdf() {
  yield takeEvery(types.GET_DOC_PDF, getDocPdf);
}

export default watchRequestPdf;
