export default (error) => {
  const statusCode = error.response && error.response.status;
  const msg = error.response && error.response.data && (!(error.response.data instanceof Blob))? error.response.data :
         (error.message ? error.message : "Ошибка обработки запроса");

  //console.log(msg);

  const txt = msg.message ? msg.message : msg;

  return { text: txt, statusCode: statusCode };

}
