import * as types from "../constants/action-types";

const initialState = {
  payload: null,
  error: null
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  //console.log("payload", payload);
  switch (type) {
    case types.GET_PAYMENT_RESULT_DONE:
    case types.START_PAYMENT_DONE:
      return { ...state, error: false, payload };
    default:
      return state;
  }
};
