import React from 'react';
import {Dialog, DialogContent, DialogContentText, Hidden, withStyles} from '@material-ui/core';
import Loader from 'react-loader-spinner'
import {connect} from 'react-redux';

const styles = theme => ({
  progress: {
    width: '340px',
    height: '190px',
    textAlign: 'center'
  }
})

const ProgressAlert = (props) => {
  const { classes, isLoading } = props;

  return(
    <Dialog open={ isLoading }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"    >
      <Hidden xsDown>
        <DialogContent className={ classes.progress } >
          <Loader type="Watch" color="green" height={90} width={90} />
          <DialogContentText style={{marginTop: '20px', fontWeight: 'bold'}}> Выполняется обработка данных... </DialogContentText>
        </DialogContent>
      </Hidden>
      <Hidden smUp>
        <DialogContent className={ classes.progress } style={{width: '280px'}}>
          <Loader type="Watch" color="green" height={90} width={90} />
          <DialogContentText style={{marginTop: '20px', fontWeight: 'bold'}}> Выполняется обработка данных... </DialogContentText>
        </DialogContent>
      </Hidden>

    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    isLoading : state.loading.isLoading,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ProgressAlert));

